let cloudConfig = [];

cloudConfig['development'] = {
  apiKey: "AIzaSyBLIPHwA4YySVaKwO0b6D68UXLl96g4Qvk",
  authDomain: "dev-cloud-c33eb.firebaseapp.com",
  databaseURL: "https://dev-cloud-c33eb.firebaseio.com",
  projectId: "dev-cloud-c33eb",
  storageBucket: "dev-cloud-c33eb.appspot.com",
  messagingSenderId: "1005953222899",
  appId: "1:1005953222899:web:95d7f26fac2af02ae2cf86",
  measurementId: "G-P4DWZZWXJH"
};

cloudConfig['stage'] = {
  apiKey: "AIzaSyC-Jo0VRcqtURw8LhfvT7xeyf91pp2xx04",
  authDomain: "fir-268819.firebaseapp.com",
  databaseURL: "https://fir-268819.firebaseio.com",
  projectId: "firebase-268819",
  storageBucket: "firebase-268819.appspot.com",
  messagingSenderId: "960018012430",
  appId: "1:960018012430:web:0a7ce83d5a42fbdca4df5f",
  measurementId: "G-HKJXJ2SJ2G"
};


cloudConfig['production'] = {
  apiKey: "AIzaSyBokLfe-Rh1L_hp-ZBXS2_tRYcTMaLGGBs",
  authDomain: "cloud-firexale-com.firebaseapp.com",
  databaseURL: "https://cloud-firexale-com.firebaseio.com",
  projectId: "cloud-firexale-com",
  storageBucket: "cloud-firexale-com.appspot.com",
  messagingSenderId: "818304138480",
  appId: "1:818304138480:web:8c1d40f2705f2ad7c85635",
  measurementId: "G-6ZXL9VD7J2"
};

export default cloudConfig;
