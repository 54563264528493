import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Material UI
import {
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import StopIcon from '@material-ui/icons/Stop';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Store
import CustomerStoreContext from '../../store/CustomerStore';
import SnackStoreContext from '../../store/SnackStore';

// Components
import Modal from '../../components/organisms/Modal';
import Loader from '../../components/atoms/Loader';
import XaleForm from '../../components/organisms/XaleForm';
import Status from '../../components/atoms/Status';
import Confirmation from '../../components/organisms/Confirmation';
import Welcome from '../../components/molecules/Welcome';
import ShadowBox from '../../components/molecules/ShadowBox';
import { withFirebase } from '../../components/Firebase';

// Constants
import * as ERRORS from '../../constants/errors';
import { XALES_EVALUATION } from '../../constants/routes';

import QRCode from '../../assets/images/qrcode.svg';

// Stylesheet
import './Xales.scss';


function XalesBase(props) {
  const customerStore = useContext(CustomerStoreContext);
  const snackStore = useContext(SnackStoreContext);
  const [xales, setXales] = useState<any | null>([]);
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    checkIfCustomerCanCreateXales();
    onSnapshot();
  }, []);

  function onSnapshot() {
    props.firebase.db.collection('xales').where("companyId", "==", customerStore.customer.companyId).onSnapshot(snapshot => {
      let arr: any = [];
      snapshot.forEach(doc => {
        let obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });

      customerStore.setXales(arr);
      setXales(customerStore.getXales());
    });
  }

  function checkIfCustomerCanCreateXales() {
    const { locations, products, company } = customerStore;

    if (locations.length > 0 && products.length > 0 && company.logo && company.stripeAccountId) {
      setIsReady(true);
    }
  }

  function addXale(data, qrCodes) {
    return new Promise((resolve, reject) => {
      props.firebase.setXale({ ...data, companyId: customerStore.customer.companyId, running: false, redeemed: 0 })
        .then(data => {
          if (qrCodes.length > 0) {
            props.firebase.setQrCodes(qrCodes, data.id).then(() => {
              snackStore.setSuccess('Xale wurde erfolgreich hinzugefügt.');
              resolve(data);
            }).catch(error => {
              snackStore.setError(ERRORS.ADD_XALE);
              reject(error);
            });
          }
          else {
            snackStore.setSuccess('Xale wurde erfolgreich hinzugefügt.');
            resolve(data);
          }
        })
        .catch(error => {
          snackStore.setError(ERRORS.ADD_XALE);
          reject(error);
        })
    });
  }

  function updateXale(data, qrCodes, xaleId) {
    return new Promise((resolve, reject) => {
      props.firebase.updateXale(xaleId, data)
        .then(data => {
          if (qrCodes.length > 0) {
            props.firebase.setQrCodes(qrCodes, xaleId).then(() => {
              snackStore.setSuccess('Xale wurde erfolgreich hinzugefügt.');
              resolve(data);
            }).catch(error => {
              snackStore.setError(ERRORS.ADD_XALE);
              reject(error);
            });
          }
          else {
            snackStore.setSuccess('Xale wurde erfolgreich bearbeitet.');
            resolve(data);
          }
        })
        .catch(error => {
          snackStore.setError(ERRORS.UPDATE_XALE);
          reject(error);
        });
    });
  }

  function deleteXale(xaleId) {
    return new Promise((resolve, reject) => {
      props.firebase.deleteXale(xaleId)
        .then(data => {
          snackStore.setSuccess('Xale wurde erfolgreich gelöscht.');
          resolve(data);
        })
        .catch(error => {
          snackStore.setError(ERRORS.DELETE_XALE);
          reject(error);
        });
    });
  }

  function stopXale(xaleId) {
    return new Promise((resolve, reject) => {
      props.firebase.stopXale(xaleId)
        .then(data => {
          snackStore.setSuccess('Xale wurde erfolgreich gestoppt.');
          resolve(data);
        })
        .catch(error => {
          snackStore.setError(ERRORS.STOP_XALE);
          reject(error);
        });
    });
  }

  function getTime(date) {
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ' Uhr';
  }

  function calculateXalePrice(price: string, discount: any) {
    const productPrice = parseFloat(price.replace(',', '.'));
    const xalePriceTmp = productPrice - (productPrice * discount / 100);
    return xalePriceTmp.toFixed(2).replace('.', ',');
  }

  function NewBtn() {
    return (
      <React.Fragment>
        <AddCircleOutlineIcon /> Neuer Xale
      </React.Fragment>
    )
  }

  return (
    <div className="xales">

      {
        isReady ?
          <React.Fragment>
            <h1>Xale Manager</h1>

            <div className="button-area">
              <Modal buttonText={<NewBtn />}>
                <React.Fragment>
                  <XaleForm customerStore={customerStore} callback={addXale} />
                </React.Fragment>
              </Modal>
            </div>

            <div className="xales__items">
              {xales.length === 0 && <p>Geschafft! Du kannst jetzt deinen ersten Xale anlegen.</p>}
              {xales.length > 0 && xales.map((xale, i) => {
                const product = customerStore.products.filter(p => p.id === xale.productId)[0];

                if (!product)
                  return;

                const startTimestamp = xale.selectedStartDate.seconds * 1000;
                const start = new Date(startTimestamp);
                const endTimestamp = xale.selectedEndDate.seconds * 1000;
                const end = new Date(endTimestamp);

                return (
                  <ShadowBox className="xales__item" key={i} data-id={xale.id}>

                    <Grid container spacing={3}>

                      <Grid item xs={12} md={2} className="xales__item--center">
                        <Status type={xale.status} stopped={xale.stopped} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div className="xales__details">
                          <img src={product.image} className="product-image" />
                          <div className="xales__detailsWrapper">
                            <h3>{product.name}</h3>
                            <span className="row"><strong>Anzahl:</strong> <span className="value">{xale.countProducts}</span>{xale.hasQrCodes && <img className="xales__qrcode" src={QRCode} alt="Dieser Xale beinhaltet QR Codes" />}</span>
                            {xale.discount ?
                              <React.Fragment>
                                <span className="row"><strong>Verkaufspreis:</strong>  <span className="value">{calculateXalePrice(product.price, xale.discount)} € (-{xale.discount + '%'})</span></span>
                              </React.Fragment>
                              :
                              <span className="row"><strong>Preis:</strong>  <span className="value">{product.price + ' € (kein Rabatt)'}</span></span>
                            }
                            {xale.infoText && <span className="row"><strong>Infotext:</strong>  <span className="value">{xale.infoText}</span></span>}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div className="row">
                          <strong>Standorte: </strong>
                          {Object.values(xale.locationIds).map((location, i) => {
                            const loc = customerStore.locations.filter(l => l.id === location)[0];
                            return <span key={i} className="value">{i > 0 ? ', ' : ''}{loc ? loc.name : '-Standort wurde gelöscht-'}</span>
                          })}
                        </div>
                        <div className="row"><strong>Start:</strong> <span className="value">{getTime(start)}</span></div>
                        <div className="row"><strong>Ende:</strong> <span className="value">{getTime(end)}</span></div>
                      </Grid>
                      <Grid item xs={12} md={1} className="xales__item--center">
                        {(xale.status !== 'planning') &&
                          <Link to={{ pathname: `${XALES_EVALUATION}/${xale.id}` }}>
                            Auswertung
                          </Link>
                        }
                      </Grid>
                      <Grid item xs={12} md={2} className="xales__item--center xales__interaction">
                        {(xale.status === 'planning') &&
                          <React.Fragment>

                            <Modal button={
                              <IconButton aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            }>
                              <React.Fragment>
                                <XaleForm customerStore={customerStore} callback={updateXale} xale={xale} onSnapshot={onSnapshot} />
                              </React.Fragment>
                            </Modal>

                            <Confirmation
                              button={
                                <IconButton aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              }
                              headline={`Möchtest Du den "${product.name}" Xale wirklich löschen?`}
                              description="Dieser Xale wird unwiderruflich gelöscht. Bist Du Dir sicher?"
                              callback={() => deleteXale(xale.id)} />
                          </React.Fragment>
                        }

                        {(xale.status === 'running') &&
                          <React.Fragment>

                            <Modal button={
                              <IconButton aria-label="edit">
                                <EditIcon />
                              </IconButton>
                            }>
                              <React.Fragment>
                                <XaleForm isLive customerStore={customerStore} callback={updateXale} xale={xale} onSnapshot={onSnapshot} />
                              </React.Fragment>
                            </Modal>

                            <Confirmation
                              button={
                                <IconButton aria-label="delete">
                                  <StopIcon />
                                </IconButton>
                              }
                              headline={`Möchtest Du den "${product.name}" Xale wirklich stoppen?`}
                              description="Dieser Xale wird vorzeitig beendet. Bist Du Dir sicher?"
                              callback={() => stopXale(xale.id)} />
                          </React.Fragment>
                        }
                      </Grid>
                    </Grid>
                  </ShadowBox>
                );
              })}
            </div>

            {loading && <Loader full />}
          </React.Fragment>

          :
          <Welcome />
      }
    </div >
  )
}


const Xales = compose(
  observer,
  withFirebase,
)(XalesBase);

export default Xales;
