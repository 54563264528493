export const SUPPORT_EMAIL = 'moin@firexale.com';

export const MAX_FILE_SIZE = 524288; // 512 kB

export const GMAPS_API_KEY = 'AIzaSyBakOS4QMDOCe8-9IbR5fvnf9pT7tyOz1U';

export const STRIPE_KEY = {
  development: 'ca_GrjA1Tp5IfZUh7WsCSCU1rzWfMg7knZu',
  stage: 'ca_GrjA1Tp5IfZUh7WsCSCU1rzWfMg7knZu',
  production: 'ca_GrjA6LrcSISObqDagBBJA774yqbW2qRL'
};

export const CLOUD_URL = {
  development: 'http://localhost:3000/', // oder https://dev.cloud.firexale.com/xales
  stage: 'https://stage.cloud.firexale.com/',
  production: 'https://cloud.firexale.com/'
};
