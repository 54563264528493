import React, { useEffect, useState, useContext } from 'react';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Material UI
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// Store
import SnackStoreContext from '../../../store/SnackStore';

// Components
import { withFirebase } from '../../../components/Firebase';
import Modal from '../../../components/organisms/Modal';
import Loader from '../../../components/atoms/Loader';
import CustomerForm from '../../../components/organisms/CustomerForm';

// Constants
import * as ROLES from '../../../constants/roles';

// Stylesheet
import './Customers.scss';

function CustomersBase(props: any) {
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState<any>({})
  const [companies, setCompanies] = useState<any>({})
  const snackStore = useContext(SnackStoreContext);

  useEffect(() => {
    dispatch();
  }, []);

  function dispatch() {

    setLoading(true);

    const getAllCustomers = props.firebase.functions.httpsCallable('getAllCustomers');
    const getAllCustomersPromise = new Promise((resolve, reject) => {
      getAllCustomers().then(function (result: any) {
        resolve(result.data);
      }).catch(function (error: any) {
      });
    });

    const getAllCompanies = props.firebase.functions.httpsCallable('getAllCompanies');
    const getAllCompaniesPromise = new Promise((resolve, reject) => {
      getAllCompanies().then(function (result: any) {
        resolve(result.data)
      }).catch(function (error: any) {
      });
    });

    Promise.all([getAllCustomersPromise, getAllCompaniesPromise]).then(values => {
      setCompanies(values[1]);
      setResults(values[0]);
      setLoading(false);
    })
  }

  function addCustomer(customer) {

    setLoading(true);

    return new Promise((resolve, reject) => {
      const addCustomer = props.firebase.functions.httpsCallable('addCustomer');
      addCustomer({ customer: customer }).then(data => {
        setLoading(false);
        snackStore.setSuccess('Nutzer wurde erfolgreich hinzugefügt.');
        dispatch();
        resolve(data);
      }).catch(error => {
        setLoading(false);
        reject(error);
      })
    });
  }

  function updateCustomer(customer) {

    setLoading(true);

    return new Promise((resolve, reject) => {
      const updateCustomer = props.firebase.functions.httpsCallable('updateCustomer');
      updateCustomer({ customer: customer }).then(data => {
        // props.history.push('/customers');
        location.reload(); // dirty...
        setLoading(false);
        snackStore.setSuccess('Nutzer wurde erfolgreich bearbeitet.');
        dispatch();
        resolve(data);
      }).catch(error => {
        setLoading(false);
        reject(error);
      })
    });
  }

  return (
    <div className="customers">
      <h1>Nutzer</h1>

      <div className="button-area">

        <Modal buttonText="Neuer Nutzer">
          <CustomerForm companies={companies} callback={addCustomer} />
        </Modal>
      </div>

      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Kontakt</TableCell>
                <TableCell>Unternehmen</TableCell>
                <TableCell>Rolle</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {results.users && results.users.map((user: any, i: number) => {
                const userData = results.userData.filter((r: any) => r.id === user.uid)[0];
                if (!userData) return;
                const company = companies.filter(c => c.id === userData.companyId)[0];

                return (
                  <TableRow key={i}>
                    <TableCell>
                      <strong>ID:</strong> {user.uid}<br />
                      <strong>Registriert seit:</strong> {user.metadata.creationTime}<br />
                      <strong>Letzter Login:</strong> {user.metadata.lastSignInTime || '-'}<br />
                    </TableCell>
                    <TableCell>
                      {user.displayName}
                    </TableCell>
                    <TableCell>
                      {user.email}<br />
                      {userData.phone}
                    </TableCell>
                    <TableCell>
                      {company.name}<br />
                      <strong>ID:</strong> {company.id}
                    </TableCell>
                    <TableCell>
                      {userData.role === ROLES.SUPER_ADMIN && 'Super Admin'}
                      {userData.role === ROLES.COMPANY_ADMIN && 'Händler Admin'}
                      {userData.role === ROLES.COMPANY_USER && 'Mitarbeiter'}
                    </TableCell>
                    <TableCell>

                      <Modal button={
                        <IconButton aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      }>
                        <CustomerForm user={user} userData={userData} companies={companies} callback={updateCustomer} />
                      </Modal>

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {loading && <Loader full />}
    </div>
  );
}

const Customers = compose(
  observer,
  withFirebase,
)(CustomersBase);

export default Customers;
