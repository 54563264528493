import React, { useContext, useState, useRef, useEffect } from 'react';

// Material UI
import {
  Grid,
  TextField,
  Select
} from '@material-ui/core';

// NPM
import { useForm } from 'react-hook-form';

// Components
import Loader from '../../atoms/Loader';
import Button from '../../atoms/forms/Button';

// Constants
import { COMPANY_ADMIN, COMPANY_USER, SUPER_ADMIN } from '../../../constants/roles';
import { CUSTOMER_ERROR } from '../../../constants/errors';

// Stores
import ModalStoreContext from '../../../store/ModalStore';

// Stylesheet
import './CustomerForm.scss';

function CustomerForm(props) {
  const modalStore = useContext(ModalStoreContext);
  const [company, setCompany] = useState(props.companies[0].id);
  const [role, setRole] = useState(COMPANY_ADMIN);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', code: '' });
  const form: any = useRef(null);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values: any) => {
    setLoading(true);
    values.role = role;
    values.companyId = company;

    if (props.userData) {
      values.id = props.userData.id;
    }

    props.callback(values).then((data) => {
      closeModal();
      setLoading(false);
    }).catch(error => {
      setError({ message: CUSTOMER_ERROR, code: '' })
      setLoading(false);
    });
  };

  function closeModal() {
    modalStore.active = false;
  }

  useEffect(() => {

    if (props.userData) {
      setCompany(props.userData.companyId);
      setRole(props.userData.role);
    }
  }, []);


  return (
    <div className="customer-form">
      <h2>{props.userData ? `${props.userData.firstName} ${props.userData.lastName} bearbeiten` : 'Neuer Nutzer'}</h2>

      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Vorname*"
              name="firstName"
              defaultValue={props.userData ? props.userData.firstName : ''}
              error={errors.firstName ? true : false}
              helperText={errors.firstName && 'Bitte Vornamen angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nachname*"
              name="lastName"
              defaultValue={props.userData ? props.userData.lastName : ''}
              error={errors.lastName ? true : false}
              helperText={errors.lastName && 'Bitte Nachnamen angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email*"
              name="email"
              defaultValue={props.userData ? props.userData.email : ''}
              error={errors.email ? true : false}
              helperText={errors.email && 'Bitte Email angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Telefon"
              name="phone"
              defaultValue={props.userData ? props.userData.phone : ''}
              inputRef={register({})} />
          </Grid>
          <Grid item xs={6}>
            <label className="custom">Anbieter</label>
            <Select
              native
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={props.userData ? props.userData.companyId : company}
              onChange={(value: any) => { setCompany(value.target.value) }}
            >
              {Object.values(props.companies).map((company: any, i: number) => {
                return <option key={i} value={company.id}>{company.name}</option>
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <label className="custom">Rolle</label>
            <Select
              native
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={props.userData ? props.userData.role : role}
              onChange={(value: any) => { setRole(parseInt(value.target.value, 10)) }}
            >
              <option value={COMPANY_ADMIN}>Händler Admin</option>
              <option value={COMPANY_USER}>Mitarbeiter</option>
              <option value={SUPER_ADMIN}>Super Admin</option>
            </Select>
          </Grid>
          {!props.userData &&
            <Grid item xs={12}>
              <span className="legal">
                Hinweis: Der Zugang wird an die Email Adresse des Kunden geschickt.<br />
              </span>
            </Grid>
          }


          <Grid item xs={12}>
            <span className="error">{error.message}</span>
          </Grid>
          <Grid item xs={12}>
            {loading ?
              <Loader /> :
              <React.Fragment>
                <button type="submit" className="button primary">Speichern</button>
                <Button onClick={closeModal} variant="tertiary">Abbrechen</Button>
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default CustomerForm;
