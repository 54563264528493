import React, { useRef, useContext, useState } from 'react';
import {
  Grid,
  TextField,
} from '@material-ui/core';
import './ProductForm.scss';
import { useForm } from 'react-hook-form';
import ModalStoreContext from '../../../store/ModalStore';
import Loader from '../../atoms/Loader';
import Button from '../../atoms/forms/Button';
import { SUPPORT_EMAIL, MAX_FILE_SIZE } from '../../../constants/generals';

function ProductForm(props) {
  const form: any = useRef(null);
  const modalStore = useContext(ModalStoreContext);
  const { handleSubmit, register, errors } = useForm();
  const [error, setError] = useState({ message: '', code: '' });
  const [imgError, setImgError] = useState({ message: '', code: '' });
  const [loading, setLoading] = useState(false);
  const [productImage, setProductImage] = useState('');
  const fileInput: any = useRef(null);

  function onSubmit(values) {

    if (productImage || (props.product && props.product.image)) {
      setLoading(true);

      let priceFloat = parseFloat(values.price.replace(',', '.'));
      let priceString = priceFloat.toFixed(2).toString().replace('.', ',');
      values.price = priceString;

      let object = values;

      const productId = props.product ? props.product.id : '';
      props.callback(object, productImage || '', productId).then((data) => {
        closeModal();
        setLoading(false);
      }).catch(error => {
        setError(error.data)
        setLoading(false);
      });
    }
    else {
      setError({
        ...error,
        message: 'Bitte ein Produktbild hochladen',
      });
    }
  }

  function closeModal() {
    modalStore.active = false;
  }

  function onChangeFileInput() {
    if (fileInput.current.files[0].size > MAX_FILE_SIZE) {
      setImgError({
        ...error,
        message: 'Das Bild ist zu groß. Maximal ' + (MAX_FILE_SIZE / 1024) + 'kB.',
      });
      return;
    }
    if (fileInput.current.files.length > 0) {
      setProductImage(fileInput.current.files[0]);
      setImgError({
        ...error,
        message: '',
      });
    }
  }

  return (
    <div className="product-form">
      {props.product ? <h2>{props.product.name} bearbeiten</h2>
        : <h2>Neues Produkt</h2>}

      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              className="margin-bottom"
              label="Produktname* (max. 50 Zeichen)"
              name="name"
              defaultValue={props.product ? props.product.name : ''}
              error={errors.name ? true : false}
              helperText={errors.name && 'Bitte Produktnamen angeben'}
              inputProps={{
                maxLength: 50,
              }}
              inputRef={register({
                required: 'Required'
              })} />
            <TextField
              fullWidth
              label="Produktbeschreibung (max. 1000 Zeichen)*"
              name="description"
              rows={16}
              multiline={true}
              defaultValue={props.product ? props.product.description : ''}
              error={errors.description ? true : false}
              helperText={errors.description && 'Bitte Produktbeschreibung angeben'}
              inputProps={{
                maxLength: 1000,
              }}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={12} md={6}>

            <TextField
              fullWidth
              className="margin-bottom"
              label="Regulärer Preis in EUR*"
              name="price"
              type="text"
              defaultValue={props.product ? props.product.price : ''}
              error={errors.price ? true : false}
              helperText={errors.price && 'Bitte gültigen Produktpreis angeben'}
              inputRef={register({
                required: 'Required',
                pattern: /^\d{0,8}(\,\d{1,2})?$/
              })} />

            <div className="product-image">
              <label htmlFor="contained-button-file">
                <span className="button tertiary">Upload Produkbild*</span>
              </label>
              <span className="notice">
                Bitte achte darauf, ein Bild in guter Qualität und nach Möglichkeit freigestellt hochzuladen. Daher wähle bitte ein PNG aus. Bei Fragen
                                kannst du dich natürlich jeder Zeit an den Support&nbsp;(<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>) werden.
                            </span>
              <span className="error">{imgError.message}</span>

              {productImage ?
                <React.Fragment>
                  <div className="product-image-preview">
                    <img src={window.URL.createObjectURL(productImage)} />
                  </div>
                </React.Fragment> :

                props.product && (
                  <React.Fragment >
                    <div className="product-image-preview">
                      <img src={props.product.image} />
                    </div>
                  </React.Fragment>
                )

              }

              <input
                ref={fileInput}
                onChange={onChangeFileInput}
                accept="image/jpeg, image/png"
                id="contained-button-file"
                style={{ display: 'none' }}
                multiple
                type="file"
              />

            </div>
          </Grid>


          <Grid item xs={12}>
            <span className="error">{error.message}</span>
          </Grid>
          <Grid item xs={12}>
            {loading ?
              <Loader /> :
              <React.Fragment>
                <button type="submit" className="button primary">Speichern</button>
                <Button onClick={closeModal} variant="tertiary">Abbrechen</Button>
              </React.Fragment>
            }
          </Grid>

        </Grid>
      </form>
    </div >
  );
}

export default ProductForm;
