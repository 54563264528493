import React, { useContext, useState, useRef, useEffect } from 'react';

// Material UI
import {
  Grid,
  TextField,
  Select
} from '@material-ui/core';

// NPM
import { useForm } from 'react-hook-form';

// Components
import Loader from '../../atoms/Loader';
import Button from '../../atoms/forms/Button';

// Constants
import categories from '../../../constants/categories';

// Stores
import ModalStoreContext from '../../../store/ModalStore';

// Styles
import './CompanyForm.scss';

function CompanyForm(props) {
  const modalStore = useContext(ModalStoreContext);
  const [category, setCategory] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', code: '' });
  const form: any = useRef(null);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values: any) => {

    setLoading(true);

    values.categoryId = category;
    values.created_at = Date.now();
    values.updated_at = Date.now();

    if (props.company) {
      values.id = props.company.id;
    }

    props.callback(values).then((data) => {
      closeModal();
      setLoading(false);
    }).catch(error => {
      setError(error.data)
      setLoading(false);
    });
  }

  function closeModal() {
    modalStore.active = false;
  }

  useEffect(() => {
    if (props.company) {
      setCategory(props.company.categoryId);
    }
  }, []);

  return (
    <div className="company-form">
      <h2>{props.company ? props.company.name + ' bearbeiten' : 'Neuer Anbieter'}</h2>

      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>

          <Grid item xs={6}>

            <TextField
              fullWidth
              label="Firma*"
              name="name"
              defaultValue={props.company ? props.company.name : ''}
              error={errors.name ? true : false}
              helperText={errors.name && 'Bitte Firmenname angeben'}
              inputRef={register({
                required: 'Required'
              })} />


          </Grid>
          <Grid item xs={6}>
            <label className="custom">Kategorie</label>
            <Select
              native
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={props.company ? props.company.categoryId : ''}
              onChange={(value: any) => { setCategory(value.target.value) }}
            >
              {categories.map((cat: any, i: number) => {
                return <option key={i} value={cat.id} >{cat.name}</option>
              })}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Email (zB info@...)*"
              name="email"
              defaultValue={props.company ? props.company.email : ''}
              error={errors.email ? true : false}
              helperText={errors.email && 'Bitte Email angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Telefon*"
              name="phone"
              defaultValue={props.company ? props.company.phone : ''}
              error={errors.phone ? true : false}
              helperText={errors.phone && 'Bitte Telefonnummer angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Website"
              name="website"
              defaultValue={props.company ? props.company.website : ''}
              helperText={errors.website && 'Bitte eine gültige Website angebeen (zB. https://firexale.com/)'}
              inputRef={register({
                pattern: {
                  value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Bitte eine gültige Website angebeen (zB. https://firexale.com/)"
                }
              })} />
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              label="Straße*"
              name="street"
              defaultValue={props.company ? props.company.street : ''}
              error={errors.street ? true : false}
              helperText={errors.street && 'Bitte Straße angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Nr*"
              name="number"
              defaultValue={props.company ? props.company.number : ''}
              error={errors.number ? true : false}
              helperText={errors.number && 'Bitte Hausnummer angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="PLZ*"
              name="plz"
              defaultValue={props.company ? props.company.plz : ''}
              error={errors.plz ? true : false}
              helperText={errors.plz && 'Bitte PLZ angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              label="Stadt*"
              name="city"
              defaultValue={props.company ? props.company.city : ''}
              error={errors.city ? true : false}
              helperText={errors.city && 'Bitte Stadt angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={12}>
            <span className="error" dangerouslySetInnerHTML={{ __html: error.message }} />
          </Grid>
          <Grid item xs={12}>
            {loading ?
              <Loader /> :
              <React.Fragment>
                <button type="submit" className="button primary">Speichern</button>
                <Button onClick={closeModal} variant="tertiary">Abbrechen</Button>
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </form>
    </div >
  )
}

export default CompanyForm;
