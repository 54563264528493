import { observable } from 'mobx';
import { createContext } from "react";

class ModalStore {
    @observable active: boolean = false;

}

const ModalStoreContext = createContext(new ModalStore());

export default ModalStoreContext;