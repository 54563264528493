import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

//Material UI
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from '@material-ui/core';

import { withFirebase } from '../../components/Firebase';
import Loader from '../../components/atoms/Loader';
import ShadowBox from '../../components/molecules/ShadowBox';

import './XalesEvaluation.scss';

function XalesEvaluation(props: any) {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true);

    props.firebase.db
      .collection('user2xale')
      .where('xaleId', '==', props.match.params.xaleId)
      .orderBy('date', 'desc')
      .onSnapshot((snapshots: any) => {
        const snapshot: any = [];
        snapshots.forEach((doc: any) => {
          const obj = doc.data();
          obj.id = doc.id;
          snapshot.push(obj);
        });
        setLoading(false);
        setData(snapshot);
      });
  }, []);

  function getFullDate(time: number) {
    const date = new Date(time * 1000);

    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const hours = date.getHours();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}, ${hours}:${minutes}`;
  }

  return (
    <div className="xales-evaluation">
      <h1>Xale Auswertung</h1>
      <span className="link" onClick={() => history.goBack()}>Zurück</span>

      <div className="xales-evaluation__items">
        <h3 className="xales-evaluation__count">{data.length} {data.length === 1 ? 'Kauf' : 'Käufe'}</h3>

        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '30%' }}>Name</TableCell>
                  <TableCell style={{ width: '30%' }}>Gekauft am</TableCell>
                  <TableCell style={{ width: '10%' }}>Anzahl Produkte</TableCell>
                  <TableCell style={{ width: '30%' }}>Entwertet am</TableCell>
                  {/* <TableCell style={{ width: '20%' }}>Aktionen</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item: any, i) => {
                  return (
                    <TableRow key={i} hover data-id={item.id}>
                      <TableCell>
                        {item.user.name}
                      </TableCell>
                      <TableCell>
                        {getFullDate(item.date.seconds)}
                      </TableCell>
                      <TableCell>
                        {item.numberOfProducts}
                      </TableCell>
                      <TableCell>
                        {item.devalueDate ? getFullDate(item.devalueDate.seconds) : '-'}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
      {loading ? <Loader full /> : ''}
    </div>
  );
}


export default compose(withRouter, withFirebase)(XalesEvaluation);
