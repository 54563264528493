import React, { useState, useContext, useEffect } from 'react'

// Material UI
import {
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Components
import { withFirebase } from '../../components/Firebase';
import Modal from '../../components/organisms/Modal';
import Confirmation from '../../components/organisms/Confirmation';
import Loader from '../../components/atoms/Loader';
import LocationForm from '../../components/organisms/LocationForm';
import Status from '../../components/atoms/Status';
import ShadowBox from '../../components/molecules/ShadowBox';

// Stores
import CustomerStoreContext from '../../store/CustomerStore';
import SnackStoreContext from '../../store/SnackStore';

// Contstants
import { DAYS } from '../../constants/dates';

// Stylesheet
import './Locations.scss';



function LocationsBase(props) {
  const customerStore = useContext(CustomerStoreContext);
  const snackStore = useContext(SnackStoreContext);
  const [loading, setLoading] = useState(false);
  const [xales, setXales] = useState(customerStore.getXales());

  useEffect(() => {

    // TODO: Eigentlich müssten alle Xales im Store sein. Funzt aber nicht!
    // setXales(customerStore.getXales());
    props.firebase.db.collection('xales').where("companyId", "==", customerStore.customer.companyId).onSnapshot(snapshot => {
      let arr: any = [];
      snapshot.forEach(doc => {
        let obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });

      customerStore.setXales(arr);
      setXales(customerStore.getXales());
    })

  }, []);

  function dispatch() {

    setLoading(true);

    props.firebase.getLocations(customerStore.customer.companyId)
      .then(snapshot => {
        let arr: any = [];
        snapshot.forEach(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });
        customerStore.setLocations(arr);
        setLoading(false);
      })
  }


  function addLocation(data) {

    return new Promise((resolve, reject) => {

      props.firebase.setLocation(customerStore.customer.companyId, data)
        .then(data => {
          dispatch();
          snackStore.setSuccess('Standort wurde erfolgreich hinzugefügt.');
          resolve(data);
        })
        .catch(error => {
          reject(error);
        })
    });
  }

  function disableLocation(locationId) {

    props.firebase.disableLocation(locationId)
      .then(data => {
        dispatch();
        snackStore.setSuccess('Standort wurde erfolgreich gelöscht.');
      })
      .catch(error => {
        console.log('error', error)
      })
  }

  function updateLocation(data, locationId) {

    return new Promise((resolve, reject) => {
      // resolve();
      props.firebase.updateLocation(data, locationId)
        .then(data => {
          dispatch();
          snackStore.setSuccess('Standort wurde erfolgreich bearbeitet.');
          resolve(data);
        })
        .catch(error => {
          console.log('error1', error)
          reject(error);
        })
    });
  }

  function NewBtn() {
    return (
      <React.Fragment>
        <AddCircleOutlineIcon /> Neuer Standort
      </React.Fragment>
    )
  }

  return (
    <div className="locations">
      <h1>Standorte</h1>

      <div className="button-area">

        <Modal buttonText={<NewBtn />}>
          <React.Fragment>
            <LocationForm callback={addLocation} />
          </React.Fragment>
        </Modal>
      </div>

      <div className="locations__items">
        <Grid container spacing={3}>
          {customerStore.locations.length > 0 && customerStore.locations.map((location, i) => {

            let activeProducts = xales.filter(x => x.status === 'planning' || x.status === 'running');
            activeProducts = activeProducts.filter(x => x.locationIds.indexOf(location.id) !== -1)

            return (
              <Grid item xs={12} md={6} lg={4} key={i}>
                <ShadowBox className="locations__item" key={i}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} md={12} className="">
                      <h3>{location.name}</h3>
                      {location.street} {location.number}<br />
                      {location.plz} {location.city}
                    </Grid>
                    <Grid item xs={12} md={12} className="">
                      {location.email}<br />
                      {location.phone}<br />
                      {location.website}
                    </Grid>
                    <Grid item xs={12} md={12} className="">
                      {location.openingHours.map((hours, h) => {
                        const day = DAYS.filter(d => d.slug === hours.day)[0]
                        return (
                          <React.Fragment key={h}>
                            <div className="row">
                              <strong>{day.name}: </strong>
                              <span className="value">{hours.hours.start} bis {hours.hours.end}</span>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                    <Grid item xs={12} md={12} className="locations__interaction">
                      {activeProducts.length === 0 ?
                        <React.Fragment>
                          <Modal button={
                            <IconButton aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          }>
                            <React.Fragment>
                              <LocationForm callback={updateLocation} location={location} />
                            </React.Fragment>
                          </Modal>

                          <Confirmation
                            button={
                              <IconButton aria-label="delete">
                                <DeleteIcon />
                              </IconButton>
                            }
                            headline={`Möchtest Du "${location.name}" wirklich löschen?`}
                            description="Dieser Standort wird unwiderruflich gelöscht. Bist Du Dir sicher?"
                            callback={() => disableLocation(location.id)} />
                        </React.Fragment>
                        :

                        <React.Fragment>
                          <Modal button={
                            <IconButton aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          }>
                            <React.Fragment>
                              <LocationForm callback={updateLocation} location={location} />
                            </React.Fragment>
                          </Modal>
                          <Status type="running" msg="Xale ist aktiv" />
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                </ShadowBox>
              </Grid>
            );
          })}
        </Grid>
      </div>

      {loading && <Loader full />}
    </div>
  )
}

const Locations = compose(
  observer,
  withFirebase,
)(LocationsBase);

export default Locations;
