import React, { useEffect } from 'react';
// import { observer } from "mobx-react-lite";
import Routes from './Routes';
import * as Sentry from '@sentry/browser';
import './scss/main_output.scss';

if (
  process.env.NODE_ENV === 'production'
) {
  Sentry.init({dsn: "https://51e5564886044951a886a22b776ed1bf@o404435.ingest.sentry.io/5268141"});
}

function App() {

  useEffect(() => {
    const env: any = process.env.REACT_APP_ENVIRONMENT;
    document.documentElement.classList.add(env);
  });

  return (
    <Routes />
  )
}

export default App;
