import * as firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import cloudConfig from './config';
const env: any = process.env.REACT_APP_ENVIRONMENT;
const config: any = cloudConfig[env];
class Firebase {
  auth: any;
  db: any;
  functions: any;
  storage: any;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
      this.auth = firebase.auth();
      this.db = firebase.firestore();
      this.functions = firebase.app().functions('europe-west1');
      this.storage = firebase.storage();
    }
  }

  doSignInWithEmailAndPassword = (email: string, password: string) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doSignOut = () => this.auth.signOut();

  resetPassword = (email) => {
    return this.auth.sendPasswordResetEmail(email);
  }
  // users = () => this.db.doc('user/pOo0imULIrsxruJgZxdu');
  getCustomer = (uid: string) => {
    return this.db.collection('customers').doc(uid).get();
  }

  getCompany = (id: string) => {
    return this.db.collection('companies').doc(id).get();
  }

  updateCompany = (id: string, company) => {
    return this.db.collection('companies').doc(id).update(company);
  }

  setXale = (xale: any) => {
    xale.review = false;
    return this.db.collection('xales').add(xale);
  }

  setQrCodes = (codes: any, xaleId: string) => {
    return this.db.collection('qrCodes').doc(xaleId).set({ codes });
  }

  getQrCodes = (xaleId: string) => {
    return this.db.collection('qrCodes').doc(xaleId).get();
  }

  updateXale = (xaleId: string, xale) => {
    return this.db.collection('xales').doc(xaleId).update(xale);
  }

  stopXale = (xaleId: string) => {
    return this.db.collection('xales').doc(xaleId).update({ selectedEndDate: new Date(), stopped: true, running: false });
  }

  deleteXale = (xaleId: string) => {
    return this.db.collection('xales').doc(xaleId).delete();
  }

  getXales = (companyId: string) => {
    return this.db.collection('xales').where("companyId", "==", companyId).get();
  }

  setProduct(companyId: string, products: any) {
    return this.db.collection('products').add({ companyId: companyId, ...products, active: true });
  }

  getProducts(companyId: string) {
    return this.db.collection('products')
      .where("companyId", "==", companyId)
      .where("active", "==", true)
      .orderBy('name')
      .get();
  }

  updateProduct(product: any, productId: string) {
    return this.db.collection('products').doc(productId).update(product);
  }

  disableProduct(productsId: string) {
    return this.db.collection('products').doc(productsId).update({ active: false });
  }

  uploadProductImage(companyId: string, productId: string, file: any) {
    return this.storage.ref(`/products/${companyId}/${productId}.${file.type.split('/')[1]}`).put(file);
  }

  setLocation(companyId: string, location: any) {
    return this.db.collection('locations').add({ companyId: companyId, ...location, active: true });
  }

  updateLocation(location: any, locationId: string) {
    return this.db.collection('locations').doc(locationId).update(location);
  }

  disableLocation(locationId: string) {
    return this.db.collection('locations').doc(locationId).update({ active: false });
  }

  getLocations(companyId: string) {
    return this.db.collection('locations')
      .where("companyId", "==", companyId)
      .where("active", "==", true)
      .get();
  }

  uploadCompanyLogo(companyId: string, file: any) {
    const fileName = `logo-${companyId}.${file.type.split('/')[1]}`;
    return this.storage.ref(`/logos/${companyId}/${fileName}`).put(file);
  }

  getStripeDashboardUrl = (stripeAccountId: string) => {
    const getStripeDashboardUrlCall = this.functions.httpsCallable('getStripeDashboardUrl');
    return getStripeDashboardUrlCall(stripeAccountId);
  }

  oauthToken = (code: object) => {
    const oauthTokenCall = this.functions.httpsCallable('oauthToken');
    return oauthTokenCall(code);
  };

  getStorageUrl = (path: string) => {
    return `https://firebasestorage.googleapis.com/v0/b/${config.storageBucket}/o/${encodeURIComponent(path)}?alt=media`;
  }

  getContent = (doc: string) => {
    return this.db.collection('content').doc(doc).get();
  }

  setContent = (doc: string, text: string) => {
    return this.db.collection('content').doc(doc).set({ text });
  }

}

export default Firebase;
