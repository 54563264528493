import React from 'react';
import { Link } from 'react-router-dom';

// Constants
import * as ROUTES from '../../../constants/routes';

import Logo from '../../../assets/images/logo-horizontal-cloud.svg';
import './Topbar.scss';

function Topbar(props) {
  const { firstName, lastName, role } = props.customer;

  return (
    <div className="topbar">
      <div className="logo">
        <Link to={ROUTES.XALES}>
          <img src={Logo} alt="fireXale" />
        </Link>
      </div>
      <div className="topbar__logged-in-as">
        {props.stripeExpressDashboardUrl && (role === 1 || role === 2) && <a className="stripe-button" href={props.stripeExpressDashboardUrl} target="_blank">Stripe Dashboard</a>}
        <span className="key">Eingeloggt als:</span> <span className="value">{firstName} {lastName}</span>
      </div>
    </div>
  )
}

export default Topbar;
