import React, { useContext, useRef, useState, useEffect } from 'react'
import {
  Grid,
  TextField,
  Select,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import Loader from '../../atoms/Loader';
import Button from '../../atoms/forms/Button';
import ModalStoreContext from '../../../store/ModalStore';
import './LocationForm.scss';

import { TIMES, DAYS } from '../../../constants/dates';
import { GMAPS_API_KEY } from '../../../constants/generals';
import * as ERRORS from '../../../constants/errors';

const defaultTime = {
  'monday': 0,
  'tuesday': 0,
  'wednesday': 0,
  'thursday': 0,
  'friday': 0,
  'saturday': 0,
  'sunday': 0
}

function LocationForm(props) {

  const form: any = useRef(null);
  const modalStore = useContext(ModalStoreContext);
  const { handleSubmit, register, errors, setValue } = useForm();
  const [error, setError] = useState({ message: '', code: '' });
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState(true);
  const [days, setDays] = useState<any>([]);
  const [start, setStart] = useState(defaultTime);
  const [end, setEnd] = useState(defaultTime);


  const onSubmit = (values: any) => {

    let openingHours: string[] = [];
    days.map(d => {
      const start = TIMES[form.current.querySelector('#' + d + '-start').value];
      const end = TIMES[form.current.querySelector('#' + d + '-end').value];

      let x: any = {};
      x.day = d;
      x.hours = { start, end };
      openingHours.push(x);

    });

    const sortedOpeningHours: string[] = [];

    const monday: any = openingHours.filter((o: any) => o.day === 'monday');
    if (monday.length > 0) sortedOpeningHours.push(monday[0]);

    const tuesday: any = openingHours.filter((o: any) => o.day === 'tuesday');
    if (tuesday.length > 0) sortedOpeningHours.push(tuesday[0]);

    const wednesday: any = openingHours.filter((o: any) => o.day === 'wednesday');
    if (wednesday.length > 0) sortedOpeningHours.push(wednesday[0]);

    const thursday: any = openingHours.filter((o: any) => o.day === 'thursday');
    if (thursday.length > 0) sortedOpeningHours.push(thursday[0]);

    const friday: any = openingHours.filter((o: any) => o.day === 'friday');
    if (friday.length > 0) sortedOpeningHours.push(friday[0]);

    const saturday: any = openingHours.filter((o: any) => o.day === 'saturday');
    if (saturday.length > 0) sortedOpeningHours.push(saturday[0]);

    const sunday: any = openingHours.filter((o: any) => o.day === 'sunday');
    if (sunday.length > 0) sortedOpeningHours.push(sunday[0]);

    // Add to DB
    if (sortedOpeningHours.length === 0) {
      setError({
        ...error,
        message: 'Bitte Öffnungszeiten angeben.',
      });
    }
    else {
      setLoading(true);
      let object = { ...values, openingHours: sortedOpeningHours };
      let currentAddress = '';
      if (props.location) {
        currentAddress = `${props.location.street}+${props.location.number},${props.location.plz}+${props.location.city}`;
      }

      const address = `${object.street}+${object.number},${object.plz}+${object.city}`;

      // Save money and dont't fetch google API if address not changed
      if (address === currentAddress) {
        object = { ...object, coords: props.location.coords, google: props.location.google };
        callbackToParent(object);
      }
      else {
        getLongLat(address).then((googleData: any) => {
          if (googleData.results[0].geometry.location) {
            object = { ...object, coords: googleData.results[0].geometry.location, google: googleData };
            callbackToParent(object);
          }
          else {
            setError({
              ...error,
              message: ERRORS.GOOGLE_MAPS_ERROR,
            });
            setLoading(false);
          }
        }).catch(e => {
          setError({
            ...error,
            message: ERRORS.GOOGLE_MAPS_ERROR1,
          });
          setLoading(false);
        });
      }
    }
  }

  function callbackToParent(object) {
    const locationId = props.location ? props.location.id : '';
    props.callback(object, locationId).then((data) => {
      closeModal();
      setLoading(false);
    }).catch(error => {
      // setError(error.data)
      setLoading(false);
    });
  }

  function closeModal() {
    modalStore.active = false;
  }

  function getLongLat(address) {

    const url = `https://maps.google.com/maps/api/geocode/json?&key=${GMAPS_API_KEY}&address=${address}`;
    return new Promise((resolve, reject) => {

      // save money
      const isLocal = (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENVIRONMENT === 'development');
      if (true) {
        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (data.error_message) {
              console.error('data', data)
            }
            resolve(data)
          })
          .catch(e => {
            console.error(e);
            reject(e)
          });
      }
      else {
        resolve({
          results: [
            {
              geometry: {
                location: {
                  lat: 11.111,
                  lng: 22.222,
                }
              }
            }
          ]
        });
      }
    });
  }

  function handleCheckbox(data) {


    const found = days.find(el => {
      return el === data;
    })

    if (found) {
      days.splice(days.indexOf(data), 1);
    }
    else {
      days.push(data)
    }

    setTest(!test);
  }

  function setDefaultOpeningHours() {
    let openedDays: string[] = [];
    let openedStart: any = [];
    let openedEnd: any = [];
    props.location.openingHours.map((item) => {
      openedDays.push(item.day);
      openedStart[item.day] = TIMES.indexOf(item.hours.start);
      openedEnd[item.day] = TIMES.indexOf(item.hours.end);
    });
    setDays(openedDays);
    setStart(openedStart);
    setEnd(openedEnd);
  }

  useEffect(() => {

    if (props.location) {
      setDefaultOpeningHours();
    }
    setValue('city', 'Hamburg');
  }, []);

  return (
    <div className="location-form">
      {props.location ? <h2>{props.location.name} bearbeiten</h2>
        : <h2>Neuer Standort</h2>}

      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Name* (max. 50 Zeichen)"
              name="name"
              defaultValue={props.location ? props.location.name : ''}
              error={errors.name ? true : false}
              helperText={errors.name && 'Bitte Vornamen angeben'}
              inputProps={{
                maxLength: 50,
              }}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email (zB info@...)*"
              name="email"
              defaultValue={props.location ? props.location.email : ''}
              error={errors.email ? true : false}
              helperText={errors.email && 'Bitte Email angeben'}
              inputRef={register({
                required: 'Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address"
                }
              })} />
          </Grid>

          <Grid item xs={8} md={10}>
            <TextField
              fullWidth
              label="Straße*"
              name="street"
              defaultValue={props.location ? props.location.street : ''}
              error={errors.street ? true : false}
              helperText={errors.street && 'Bitte Straße angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              fullWidth
              label="Nr*"
              name="number"
              defaultValue={props.location ? props.location.number : ''}
              error={errors.number ? true : false}
              helperText={errors.number && 'Bitte Hausnummer angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={4} md={2}>
            <TextField
              fullWidth
              label="PLZ*"
              name="plz"
              defaultValue={props.location ? props.location.plz : ''}
              error={errors.plz ? true : false}
              helperText={errors.plz && 'Bitte PLZ angeben'}
              inputRef={register({
                required: 'Required',
                pattern: {
                  value: /[0-9]{5}/,
                  message: "Bitte PLZ angeben"
                }
              })} />
          </Grid>
          <Grid item xs={8} md={10}>
            <Select
              native
              fullWidth
              labelId="demo-simple-select-label1"
              id="demo-simple-select1"
              defaultValue={props.location ? props.location.city : 'Hamburg'}
              onChange={(value: any) => setValue('city', value.target.value || 'Hamburg')}
              inputProps={{
                inputRef: (ref) => {
                  if (!ref) return;
                  register({
                    name: 'city',
                    required: 'Required'
                  });
                },
              }}
            >
              <option value="Lüneburg">Lüneburg</option>
              <option value="Hamburg">Hamburg</option>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Telefon*"
              name="phone"
              defaultValue={props.location ? props.location.phone : ''}
              error={errors.phone ? true : false}
              helperText={errors.phone && 'Bitte Telefonnummer angeben'}
              inputRef={register({
                required: 'Required'
              })} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Website"
              name="website"
              defaultValue={props.location ? props.location.website : ''}
              error={errors.website ? true : false}
              helperText={errors.website && 'Bitte eine gültige Website angebeen (zB. https://firexale.com/)'}
              inputRef={register({
                pattern: {
                  value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                  message: "Bitte eine gültige Website angebeen (zB. https://firexale.com/)"
                }
              })} />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Tag</TableCell>
                      <TableCell>Geöffnet</TableCell>
                      <TableCell>Start</TableCell>
                      <TableCell>Ende</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {DAYS.map((day, i) => {
                      // console.log('days', days)
                      const hasThisDayClosed = days.indexOf(day.slug) === -1;
                      // console.log('hasThisDayClosed', hasThisDayClosed)
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            {day.name}
                          </TableCell>
                          <TableCell>
                            <Checkbox checked={!hasThisDayClosed} onClick={() => handleCheckbox(day.slug)} inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} />
                          </TableCell>
                          <TableCell>
                            <Select
                              disabled={hasThisDayClosed}
                              native
                              fullWidth
                              labelId="demo-simple-select-label"
                              id={day.slug + '-start'}
                              value={start[day.slug]}
                              onChange={(value: any) => {
                                setStart({
                                  ...start,
                                  [day.slug]: (value.target.value),
                                })

                                const startInt = parseInt(value.target.value);
                                let endInt = end[day.slug] ? end[day.slug] : 1;

                                if (startInt >= endInt) {
                                  setEnd({
                                    ...end,
                                    [day.slug]: startInt + 1,
                                  })
                                }
                              }}
                            >
                              {TIMES.map((time, i) => {
                                if (i < TIMES.length - 1)
                                  return <option key={i} value={i}>{time}</option>
                              })}
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              disabled={hasThisDayClosed}
                              native
                              fullWidth
                              labelId="demo-simple-select-label"
                              id={day.slug + '-end'}
                              value={end[day.slug]}
                              onChange={(value: any) => {
                                setEnd({
                                  ...end,
                                  [day.slug]: (value.target.value),
                                })
                              }}
                            >
                              {TIMES.map((time, i) => {
                                const ii = parseInt(start[day.slug]);
                                if (i > 0)
                                  return <option key={i} value={i} disabled={i <= ii}>{time}</option>
                              })}
                            </Select>
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <span className="error" dangerouslySetInnerHTML={{ __html: error.message }} />
          </Grid>
          <Grid item xs={12}>
            {loading ?
              <Loader /> :
              <React.Fragment>
                <button type="submit" className="button primary">Speichern</button>
                <Button onClick={closeModal} variant="tertiary">Abbrechen</Button>
              </React.Fragment>
            }
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

export default LocationForm;
