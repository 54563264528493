import { SUPPORT_EMAIL } from './generals';

export const ADD_XALE = 'Fehler 100: Xale konnte nicht hinzugefügt werden.';
export const UPDATE_XALE = 'Fehler 101: Xale konnte nicht bearbeitet werden.';
export const DELETE_XALE = 'Fehler 102: Xale konnte nicht gelöscht werden.';
export const STOP_XALE = 'Fehler 103: Xale konnte nicht gestoppt werden.';

export const ADD_PRODUCT = 'Fehler 104: Produkt konnte nicht hinzugefügt werden.';
export const UPDATE_PRODUCT = 'Fehler 105: Produkt konnte nicht bearbeitet werden.';
export const DELETE_PRODUCT = 'Fehler 106: Produkt konnte nicht gelöscht werden.';

export const UPDATE_COMPANY = 'Fehler 107: Company konnte nicht bearbeitet werden.';
export const UPDATE_COMPANY1 = 'Fehler 108: Company konnte nicht bearbeitet werden.';

export const GOOGLE_MAPS_ERROR = `Fehler 109: Bitte überprüfe deine Adresse oder kontaktiere den Support: <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`;
export const GOOGLE_MAPS_ERROR1 = `Fehler 110: Bitte überprüfe deine Adresse oder kontaktiere den Support: <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`;

export const CUSTOMER_ERROR = `Fehler 111: Irgendwas lief schief!`;


export const FIREBASE_ERRORS = {
  'auth/invalid-email': 'Deine E-Mail Adresse ist ungültig.',
  'auth/user-disabled': 'Der Benutzer ist gesperrt.',
  'auth/user-not-found': 'Der Benutzer wurde nicht gefunden.',
  'auth/wrong-password': 'Dein Passwort ist leider falsch.',
  'auth/email-already-in-use': 'Deine E-Mail Adresse exisitert bereits.',
  'auth/operation-not-allowed': 'E-Mail Login ist nicht erlaubt.',
  'auth/weak-password': 'Dein Passwort muss min. 6 Zeichen haben.',
  'auth/too-many-requests': 'Zu viele Fehlversuche. Versuche es später erneut.',
  'auth/requires-recent-login':
    'Dieser Vorgang ist vertraulich und erfordert eine aktuelle Authentifizierung. Melde dich bitte erneut an, bevor du dein Passwort änderst.',
};
