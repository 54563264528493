import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Button from '../../atoms/forms/Button';
import './Confirmation.scss';

function Confirmation(props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const accept = () => {
        props.callback();
        handleClose();
    }

    return (
        <div className="confirmation">
            {props.button &&
                <span onClick={handleClickOpen}>
                    {props.button}
                </span>
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.headline}</DialogTitle>
                {props.description &&
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.description}
                        </DialogContentText>
                    </DialogContent>
                }
                <DialogActions>

                    <Button onClick={handleClose} color="secondary">Abbrechen</Button>
                    <Button onClick={accept} color="secondary" autoFocus>Akzeptieren</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

export default Confirmation;
