import React, { useEffect, useContext, useState } from 'react';

// NPM
import { observer } from "mobx-react-lite";

// Components
import Loader from '../../../components/atoms/Loader';
import CustomerStoreContext from '../../../store/CustomerStore';
import SnackStoreContext from '../../../store/SnackStore';

// Stylesheet
import './Content.scss'

// Material UI
import {
  Snackbar
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';


function Content(props: any) {
  const customerStore = useContext(CustomerStoreContext);
  const snackStore = useContext(SnackStoreContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (props.customer.firstName) {
      if (JSON.stringify(props.customer) !== JSON.stringify(customerStore.customer)) {
        customerStore.setCustomer(props.customer);
      }
      if (JSON.stringify(props.company) !== JSON.stringify(customerStore.company)) {
        customerStore.setCompany(props.company);
      }
      if (JSON.stringify(props.locations) !== JSON.stringify(customerStore.locations)) {
        customerStore.setLocations(props.locations);
      }
      if (JSON.stringify(props.products) !== JSON.stringify(customerStore.products)) {
        customerStore.setProducts(props.products);
      }
      if (JSON.stringify(props.xales) !== JSON.stringify(customerStore.xales)) {
        customerStore.setXales(props.xales);
      }
      setLoading(false);
    }
  }, []);

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <div className="content">
      <div className="content__container">
        {!loading && props.children}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackStore.success.show} autoHideDuration={6000}
        onClose={() => snackStore.setSuccess('', false)}>
        <Alert onClose={() => snackStore.setSuccess('', false)} severity="success">
          {snackStore.success.msg}
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackStore.error.show} autoHideDuration={6000}
        onClose={() => snackStore.setError('', false)}>
        <Alert onClose={() => snackStore.setError('', false)} severity="error">
          {snackStore.error.msg}
        </Alert>
      </Snackbar>

      {loading && <Loader full />}
    </div>
  )
}

export default observer(Content);
