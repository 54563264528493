import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import { withFirebase } from './components/Firebase';
import * as ROUTES from './constants/routes';
import Login from './pages/Login';
import XalesEvaluation from './pages/XalesEvaluation';
import Settings from './pages/Settings';
import Xales from './pages/Xales';
import Locations from './pages/Locations';
import Products from './pages/Products';
import Companies from './pages/Admin/Companies';
import Customers from './pages/Admin/Customers';
import CMS from './pages/Admin/Cms';
import Sidebar from './components/organisms/Sidebar';
import Topbar from './components/organisms/Topbar';
import Content from './components/organisms/Content';

interface IUser {
  uid: String,
  displayName: String;
  photoURL: String;
  email: String;
  emailVerified: Boolean;
  firstName: String;
  lastName: String;
  phoneNumber: String;
  role: Number;
}

function Routes(props: any) {
  const [loggedIn, setLoggedIn] = useState(false);
  const [luser, setUser] = useState<any>();
  const [lcompany, setCompany] = useState<any>();
  const [llocations, setlocations] = useState<any>();
  const [lproducts, setProducts] = useState<any>();
  const [lxales, setXales] = useState<any>();

  useEffect(() => {

    props.firebase.auth.onAuthStateChanged((user: any) => {
      if (user) {
        props.firebase.getCustomer(user.uid).then((doc: any) => {
          if (doc.exists) {
            let data = doc.data();
            setUser({
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
              emailVerified: user.emailVerified,
              firstName: data.firstName,
              lastName: data.lastName,
              phoneNumber: data.phoneNumber,
              role: data.role,
              companyId: data.companyId
            });

            const companyPromise = new Promise((resolve, reject) => {
              props.firebase.getCompany(data.companyId).then((doc: any) => {
                let data = doc.data();

                props.firebase.getStripeDashboardUrl({ stripeAccountId: data.stripeAccountId }).then(link => {
                  resolve({
                    ...data,
                    id: doc.id,
                    stripeExpressDashboardUrl: link.data.url
                  });
                }).catch(() => {
                  resolve({
                    ...data,
                    id: doc.id
                  });
                });
              })
            });

            const locationPromise = new Promise((resolve, reject) => {
              props.firebase.getLocations(data.companyId)
                .then(snapshot => {
                  let arr: any = [];
                  snapshot.forEach(doc => {
                    let obj = doc.data();
                    obj.id = doc.id;
                    arr.push(obj);
                  });
                  resolve(arr);
                });
            });

            const productsDataPromise = new Promise((resolve, reject) => {

              props.firebase.getProducts(data.companyId)
                .then(snapshot => {
                  let arr: any = [];
                  snapshot.forEach(doc => {
                    let obj = doc.data();
                    obj.id = doc.id;
                    arr.push(obj);
                  });
                  resolve(arr);
                }).catch(e => {
                  resolve([]);
                });
            });

            const xalesPromise = new Promise((resolve, reject) => {

              props.firebase.getXales(data.companyId)
                .then(snapshot => {
                  let arr: any = [];
                  snapshot.forEach(doc => {
                    let obj = doc.data();
                    obj.id = doc.id;
                    arr.push(obj);
                  });

                  resolve(arr);
                });
            });

            Promise.all([companyPromise, locationPromise, productsDataPromise, xalesPromise])
              .then(values => {
                setCompany(values[0]);
                setlocations(values[1]);
                setProducts(values[2]);
                setXales(values[3]);
                setLoggedIn(true);
              });

          } else {
            console.log("No such document!");
          }
        }).catch(function (error: any) {
          console.log("Error getting document:", error);
        });

      }
      else {
        setLoggedIn(false);
        console.log('redirect')
      }
    })
  }, []);


  return (
    <Router>
      <div>
        {loggedIn &&
          <React.Fragment>
            <Topbar customer={luser} stripeExpressDashboardUrl={lcompany.stripeExpressDashboardUrl} />
            <Sidebar customer={luser} />
          </React.Fragment>
        }

        {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
        <Route path={ROUTES.LOGIN} component={Login} exact />
        <Route path={ROUTES.LOGIN_ERROR} component={Login} exact />
        {loggedIn &&
          <Content customer={luser} company={lcompany} locations={llocations} products={lproducts} xales={lxales}>
            <Switch>
              {/* <Route path={ROUTES.DASHBOARD} component={Dashboard} exact /> */}
              <Route path={ROUTES.XALES} component={Xales} exact />
              <Route path={`${ROUTES.XALES_EVALUATION}/:xaleId`} component={XalesEvaluation} exact />
              <Route path={ROUTES.PRODUCTS} component={Products} exact />
              <Route path={ROUTES.LOACTIONS} component={Locations} exact />
              <Route path={ROUTES.SETTINGS} component={Settings} exact />
              {luser.role === 1 &&
                <React.Fragment>
                  <Route path={ROUTES.COMPANIES} component={Companies} exact />
                  <Route path={ROUTES.CUSTOMERS} component={Customers} exact />
                  <Route path={ROUTES.CMS} component={CMS} exact />
                </React.Fragment>
              }
            </Switch>
          </Content>
        }
      </div>
    </Router>
  );
}

export default withFirebase(Routes);
