import React, { useContext, useState, useEffect, useRef } from 'react';
import './Settings.scss';
import { observer } from "mobx-react-lite";
import { withFirebase } from '../../components/Firebase';
import { compose } from 'recompose';
import CustomerStoreContext from '../../store/CustomerStore';
import SnackStoreContext from '../../store/SnackStore';
import Loader from '../../components/atoms/Loader';
import ShadowBox from '../../components/molecules/ShadowBox';
import { TextField, Grid, Button, Select, MenuItem } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import categories from '../../constants/categories';

// Constants
import { SUPPORT_EMAIL, MAX_FILE_SIZE } from '../../constants/generals';
import * as ERRORS from '../../constants/errors';

function SettingsBase(props: any) {
  const customerStore = useContext(CustomerStoreContext);
  const snackStore = useContext(SnackStoreContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ message: '', code: '' });
  const [companyImage, setCompanyImage] = useState('');
  const [category, setCategory] = useState(1);
  const { handleSubmit, register, errors } = useForm();
  const form: any = useRef(null);
  const fileInput: any = useRef(null);

  useEffect(() => {
    if (customerStore.company.name) {
      setLoading(false);
    }

  }, [customerStore.company]);

  const onSubmit = (values: any) => {

    if (companyImage || customerStore.company.logo) {
      updateCompany({ ...values, categoryId: category });
      setError({
        ...error,
        message: '',
      })
    }
    else {
      setError({
        ...error,
        message: 'Bitte ein Firmenlogo hochladen',
      })
    }
  }

  function updateCompany(values) {

    setLoading(true);

    props.firebase.updateCompany(customerStore.company.id, values).then(() => {

      props.firebase.getCompany(customerStore.company.id).then((doc: any) => {
        let data = doc.data();

        const uploadCompanyLogoPromise = new Promise((resolve, reject) => {
          if (companyImage) {

            props.firebase.uploadCompanyLogo(customerStore.company.id, companyImage)
              .on('state_changed', (snapShot) => {
                if (snapShot.bytesTransferred === snapShot.totalBytes) {
                  setTimeout(() => {

                    const logo = snapShot.ref.fullPath.replace(`logo-${customerStore.company.id}`, `logo-${customerStore.company.id}_200x200`);
                    const logoOriginal = snapShot.ref.fullPath;

                    props.firebase.updateCompany(customerStore.company.id, {
                      logo: props.firebase.getStorageUrl(logo),
                      logoOriginal: props.firebase.getStorageUrl(logoOriginal)
                    }).then(() => {
                      customerStore.setCompany({
                        ...data,
                        id: doc.id,
                        logo: props.firebase.getStorageUrl(logo),
                        logoOriginal: props.firebase.getStorageUrl(logoOriginal)
                      });
                      resolve(snapShot);
                    }).catch(e => {
                      console.log(e);
                      snackStore.setError(ERRORS.UPDATE_COMPANY);
                    });

                  }, 1000);
                }
                // resolve(snapShot);
              }, (err) => {
                console.log(err)
                snackStore.setError(ERRORS.UPDATE_COMPANY1);
              });
          }

          else {
            resolve();
          }
        });

        Promise.all([uploadCompanyLogoPromise])
          .then(values => {
            customerStore.setCompany({ ...data, id: customerStore.company.id, logo: customerStore.company.logo });
            snackStore.setSuccess('Daten wurde erfolgreich geändert.');
            setLoading(false);
          });
      });

    });
  }

  function onChangeFileInput() {
    if (fileInput.current.files[0].size > MAX_FILE_SIZE) {
      setError({
        ...error,
        message: 'Das Bild ist zu groß. Maximal ' + (MAX_FILE_SIZE / 1024) + 'kB.',
      });
      return;
    }
    if (fileInput.current.files.length > 0) {
      setCompanyImage(fileInput.current.files[0]);
      setError({
        ...error,
        message: '',
      })
    }
  }

  return (
    <div className="settings">
      <h1>Einstellungen</h1>


      {!loading ?
        <form ref={form} onSubmit={handleSubmit(onSubmit)}>
          <ShadowBox>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  defaultValue={customerStore.company.name}
                  fullWidth
                  label="Firma*"
                  name="name"
                  disabled={true}
                  error={errors.name ? true : false}
                  helperText={errors.name && 'Bitte Namen angeben'}
                  inputRef={register({
                    required: 'Required'
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  defaultValue={customerStore.company.email}
                  fullWidth
                  label="Email*"
                  name="email"
                  error={errors.email ? true : false}
                  helperText={errors.name && 'Bitte Email angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>

              <Grid item xs={12}>

                <label className="custom">Kategorie</label>
                <Select
                  fullWidth
                  native
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={customerStore.company.categoryId}
                  onChange={(value: any) => { setCategory(value.target.value) }}
                >
                  {categories.map((cat: any, i: number) => {
                    return <option key={i} value={cat.id} >{cat.name}</option>
                  })}
                </Select>
              </Grid>

              <Grid item xs={8} md={10}>
                <TextField
                  defaultValue={customerStore.company.street}
                  fullWidth
                  label="Straße*"
                  name="street"
                  error={errors.street ? true : false}
                  helperText={errors.street && 'Bitte Straße angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={4} md={2}>
                <TextField
                  defaultValue={customerStore.company.number}
                  fullWidth
                  label="Nr*"
                  name="number"
                  error={errors.number ? true : false}
                  helperText={errors.number && 'Bitte Hausnummer angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={4} md={8}>
                <TextField
                  defaultValue={customerStore.company.plz}
                  fullWidth
                  label="PLZ*"
                  name="plz"
                  error={errors.plz ? true : false}
                  helperText={errors.plz && 'Bitte PLZ angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={8} md={4}>
                <TextField
                  defaultValue={customerStore.company.city}
                  fullWidth
                  label="Stadt*"
                  name="city"
                  error={errors.city ? true : false}
                  helperText={errors.city && 'Bitte Stadt angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  defaultValue={customerStore.company.phone}
                  fullWidth
                  label="Telefon*"
                  name="phone"
                  error={errors.phone ? true : false}
                  helperText={errors.phone && 'Bitte Telefonnummer angeben'}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  defaultValue={customerStore.company.website}
                  fullWidth
                  label="Website"
                  name="website"
                  helperText={errors.website && 'Bitte eine gültige Website angebeen (zB. https://firexale.com/)'}
                  inputRef={register({
                    pattern: {
                      value: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      message: "Bitte eine gültige Website angebeen (zB. https://firexale.com/)"
                    }
                  })} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  defaultValue={customerStore.company.factSheet}
                  fullWidth
                  label="Steckbrief (max. 60 Zeichen)*"
                  name="factSheet"
                  error={errors.factSheet ? true : false}
                  helperText={errors.factSheet && 'Bitte einen Steckbrief angeben (max. 60 Zeichen)'}
                  inputProps={{
                    maxLength: 60,
                  }}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  defaultValue={customerStore.company.shortDescription}
                  fullWidth
                  label="Kurzbeschreibung (max. 280 Zeichen)*"
                  name="shortDescription"
                  rows={3}
                  multiline={true}
                  error={errors.factSheet ? true : false}
                  helperText={errors.factSheet && 'Bitte eine Kurzbeschreibung angeben (max. 280 Zeichen)'}
                  inputProps={{
                    maxLength: 280,
                  }}
                  inputRef={register({
                    required: 'Required'
                  })} />
              </Grid>
              <Grid item xs={12} className="company-logo">
                <label htmlFor="contained-button-file">
                  <span className="button tertiary">Upload Firmenlogo*</span>
                </label>
                <span className="notice">
                  Bitte achte darauf, ein Bild in guter Qualität und nach Möglichkeit freigestellt hochzuladen. Bei Fragen
                                kannst du dich natürlich jeder Zeit an den Support&nbsp;(<a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>) werden.
                            </span>

                {(companyImage || customerStore.company.logo) &&
                  <React.Fragment>
                    <h4>Vorschau in der App</h4>
                    <div className="logo-preview">
                      <img src={companyImage ? window.URL.createObjectURL(companyImage) : customerStore.company.logo} />
                    </div>
                  </React.Fragment>
                }

                <input
                  ref={fileInput}
                  onChange={onChangeFileInput}
                  accept="image/jpeg, image/png"
                  id="contained-button-file"
                  style={{ display: 'none' }}
                  multiple
                  type="file"
                />

              </Grid>
              <Grid item xs={12}>
                <span className="error">{error.message}</span>
              </Grid>
              <Grid item xs={12}>
                <button type="submit" className="button primary">Speichern</button>
              </Grid>
            </Grid>
          </ShadowBox>
        </form>
        : <Loader full />}


    </div>
  )
}

const Settings = compose(
  observer,
  withFirebase,
)(SettingsBase);

export default Settings;
