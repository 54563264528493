import React from 'react'
import './ShadowBox.scss';

function ShadowBox(props) {
  return (
    <div {...props}>
      <div className="shadow-box">
        {props.children}
      </div>
    </div>
  );
}

export default ShadowBox;
