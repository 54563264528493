import React from 'react'
import './Button.scss';
import { IButton } from '../../../../types/IButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

function Button(props: IButton) {
  return (
    <div {...props} className={"button " + props.variant}>
      {props.children}
    </div>
  )
}

export default Button;
