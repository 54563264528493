import React from 'react';
import { Link, withRouter } from 'react-router-dom';

// Constants
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';

// Material UI
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import WhatshotOutlinedIcon from '@material-ui/icons/WhatshotOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// Stylesheet
import './Sidebar.scss';

import Logo from '../../../assets/images/logo-horizontal-cloud.svg';

function Sidebar(props: any) {
  // console.log('props', props.location)
  const [active, setActive] = React.useState(false);

  return (
    <React.Fragment>
      <div className={"burger-nav" + (active ? ' is-active' : '')} onClick={() => setActive(!active)}></div>
      <div className={"nav-overlay" + (active ? ' is-active' : '')} onClick={() => setActive(false)}></div>
      <div className={"sidebar" + (active ? ' is-active' : '')}>
        <div className="logo">
          <Link to={ROUTES.XALES}>
            <img src={Logo} alt="fireXale" />
          </Link>
        </div>
        <ul>
          <li>
            <Link
              onClick={() => setActive(false)}
              to={ROUTES.XALES}
              className={(props.location.pathname === ROUTES.XALES) ? 'active' : ''}>
              <WhatshotOutlinedIcon /> Xale Manager
            </Link>
          </li>
          {(props.customer.role === ROLES.SUPER_ADMIN || props.customer.role === ROLES.COMPANY_ADMIN) &&
            <React.Fragment>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.PRODUCTS}
                  className={(props.location.pathname === ROUTES.PRODUCTS) ? 'active' : ''}>
                  <AppsOutlinedIcon /> Produkte
                    </Link>
              </li>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.LOACTIONS}
                  className={(props.location.pathname === ROUTES.LOACTIONS) ? 'active' : ''}>
                  <LocationOnOutlinedIcon /> Standorte
                    </Link>
              </li>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.SETTINGS}
                  className={(props.location.pathname === ROUTES.SETTINGS) ? 'active' : ''}>
                  <SettingsOutlinedIcon /> Einstellungen
                    </Link>
              </li>
            </React.Fragment>
          }
        </ul>
        {props.customer.role === ROLES.SUPER_ADMIN &&
          <React.Fragment>
            <h5>Adminbereich</h5>
            <ul>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.COMPANIES}
                  className={(props.location.pathname === ROUTES.COMPANIES) ? 'active' : ''}>
                  <BusinessOutlinedIcon /> Anbieter
                            </Link>
              </li>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.CUSTOMERS}
                  className={(props.location.pathname === ROUTES.CUSTOMERS) ? 'active' : ''}>
                  <GroupOutlinedIcon /> Nutzer
                            </Link>
              </li>
              <li>
                <Link
                  onClick={() => setActive(false)}
                  to={ROUTES.CMS}
                  className={(props.location.pathname === ROUTES.CMS) ? 'active' : ''}>
                  <TextFieldsIcon /> CMS
                            </Link>
              </li>
            </ul>
          </React.Fragment>
        }
        <Link className="logout" to={ROUTES.LOGIN}> <ExitToAppIcon /> Logout</Link>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Sidebar);
