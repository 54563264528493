import React, { useContext, useState, useEffect } from 'react';

//Material UI
import {
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Store
import CustomerStoreContext from '../../store/CustomerStore';
import SnackStoreContext from '../../store/SnackStore';

// Components
import { withFirebase } from '../../components/Firebase';
import Modal from '../../components/organisms/Modal';
import Confirmation from '../../components/organisms/Confirmation';
import Loader from '../../components/atoms/Loader';
import ProductForm from '../../components/organisms/ProductForm';
import Status from '../../components/atoms/Status';
import ShadowBox from '../../components/molecules/ShadowBox';

// Constants
import * as ERRORS from '../../constants/errors';

// Stylesheet
import './Products.scss';

function ProductsBase(props) {
  const customerStore = useContext(CustomerStoreContext);
  const snackStore = useContext(SnackStoreContext);
  const [loading, setLoading] = useState(false);
  const [xales, setXales] = useState(customerStore.getXales());

  useEffect(() => {

    // TODO: besser im Store -> siehe Locations.tsx
    props.firebase.db.collection('xales').where("companyId", "==", customerStore.customer.companyId).onSnapshot(snapshot => {
      let arr: any = [];
      snapshot.forEach(doc => {
        let obj = doc.data();
        obj.id = doc.id;
        arr.push(obj);
      });

      customerStore.setXales(arr);
      setXales(customerStore.getXales());
    });
  }, []);

  function dispatch() {
    setLoading(true);

    props.firebase.getProducts(customerStore.customer.companyId)
      .then(snapshot => {
        let arr: any = [];
        snapshot.forEach(doc => {
          let obj = doc.data();
          obj.id = doc.id;
          arr.push(obj);
        });

        customerStore.setProducts(arr);
        setLoading(false);
      });
  }

  function addProduct(data, file) {
    return new Promise((resolve, reject) => {

      props.firebase.setProduct(customerStore.customer.companyId, data)
        .then(res => {

          uploadProductImage(res.id, file).then(data => {
            setLoading(true);
            setTimeout(() => dispatch(), 1600);
            snackStore.setSuccess('Product wurde erfolgreich hinzugefügt.');
            resolve(res);
          });

        })
        .catch(error => {
          snackStore.setError(ERRORS.ADD_PRODUCT);
          reject(error);
        });
    });
  }

  function updateProduct(data, file, productId) {
    return new Promise((resolve, reject) => {

      props.firebase.updateProduct(data, productId)
        .then(res => {

          if (file) {

            uploadProductImage(productId, file).then(data => {
              setLoading(true);
              setTimeout(() => dispatch(), 400);
              snackStore.setSuccess('Product wurde erfolgreich bearbeitet.');
              resolve(res);
            });
          }

          else {

            setLoading(true);
            dispatch();
            snackStore.setSuccess('Product wurde erfolgreich bearbeitet.');
            resolve(res);
          }

        })
        .catch(error => {
          snackStore.setError(ERRORS.UPDATE_PRODUCT);
          reject(error);
        });

    });
  }

  function uploadProductImage(productId, file) {

    return new Promise((resolve, reject) => {
      props.firebase.uploadProductImage(customerStore.customer.companyId, productId, file)
        .on('state_changed', (snapShot) => {

          if (snapShot.bytesTransferred === snapShot.totalBytes) {

            const image = snapShot.ref.fullPath.replace(productId, `${productId}_200x200`);
            const imageOriginal = snapShot.ref.fullPath;

            props.firebase.updateProduct({
              image: props.firebase.getStorageUrl(image),
              imageOriginal: props.firebase.getStorageUrl(imageOriginal),
            }, productId).then(() => resolve(snapShot)).catch(err => reject(err));
          }

        }, (err) => {
          console.log(err)
          reject(err);
        });
    });
  }

  function disableProduct(id) {
    setLoading(true);

    props.firebase.disableProduct(id)
      .then(() => {
        setTimeout(() => dispatch(), 400);
        snackStore.setSuccess('Product wurde erfolgreich gelöscht.');
      }, (err) => {
        snackStore.setError(ERRORS.DELETE_PRODUCT);
      });
  }

  function NewBtn() {
    return (
      <React.Fragment>
        <AddCircleOutlineIcon /> Neues Produkt
      </React.Fragment>
    )
  }

  return (
    <div className="products">
      <h1>Produkte</h1>

      <div className="button-area">

        <Modal buttonText={<NewBtn />}>
          <React.Fragment>
            <ProductForm callback={addProduct} />
          </React.Fragment>
        </Modal>
      </div>

      <div className="products__items">

        <Grid container spacing={3}>
          {customerStore.products.length > 0 && customerStore.products.map((product, i) => {
            let activeProducts = xales.filter(x => x.productId === product.id);
            activeProducts = activeProducts.filter(x => x.status === 'planning' || x.status === 'running');
            const productImage = `${product.image}&${Math.random()}`;

            return (
              <Grid item xs={12} md={6} lg={4} key={i} data-id={product.id}>
                <ShadowBox className="products__item" key={i}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} className="products__item-head">
                      <img className="table-image" src={productImage} />
                      <h3>{product.name}</h3>
                    </Grid>
                    <Grid item xs={12} className="">
                      {product.description.length > 200 ? product.description.substring(0, 200) + '...' : product.description}
                    </Grid>
                    <Grid item xs={6} md={6} className="products__price">
                      <div className="row"> <strong>Preis:</strong> <span className="value">{product.price} €</span></div>
                    </Grid>
                    <Grid item xs={6} md={6} className="products__interaction">
                      {activeProducts.length === 0 ?

                        <React.Fragment>
                          <Modal button={
                            <IconButton aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          }>
                            <React.Fragment>
                              <ProductForm callback={updateProduct} product={product} />
                            </React.Fragment>
                          </Modal>

                          <Confirmation
                            button={
                              <IconButton aria-label="delete">
                                <DeleteIcon />
                              </IconButton>
                            }
                            headline={`Möchtest Du "${product.name}" wirklich löschen?`}
                            description="Dieses Produkt wird unwiderruflich gelöscht. Bist Du Dir sicher?"
                            callback={() => disableProduct(product.id)} />
                        </React.Fragment>

                        :

                        <React.Fragment>
                          <Modal button={
                            <IconButton aria-label="edit">
                              <EditIcon />
                            </IconButton>
                          }>
                            <React.Fragment>
                              <ProductForm callback={updateProduct} product={product} />
                            </React.Fragment>
                          </Modal>
                          <Status type="running" msg="Xale ist aktiv" />
                        </React.Fragment>
                      }
                    </Grid>

                  </Grid>
                </ShadowBox>
              </Grid>
            );
          })}
        </Grid>
      </div>

      {loading ? <Loader full /> : ''}

    </div>
  )
}

const Products = compose(
  observer,
  withFirebase,
)(ProductsBase);

export default Products;
