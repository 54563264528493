import React, { useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import './Loader.scss';

interface ILoader {
    full?: boolean;
}
function Loader(props: ILoader) {

    useEffect(() => {

    });

    return (
        <React.Fragment>
            {
                props.full ?
                    <div className="loader">
                        <CircularProgress color="secondary" />
                    </div> :
                    <CircularProgress color="secondary" />
            }
        </React.Fragment>

    );
}

export default Loader;