import React, { useState, useEffect } from 'react'

import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../components/Firebase';

// Material UI
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Components
import Modal from '../../../components/organisms/Modal';
import CompanyForm from '../../../components/organisms/CompanyForm';
import Loader from '../../../components/atoms/Loader';
import categories from '../../../constants/categories';

// Types
import { ICompany } from '../../../types/ICompany';

// Stylesheet
import './Companies.scss';


function CompaniesBase(props: any) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any>({});


  useEffect(() => {
    dispatch();
  }, []);

  function dispatch() {

    setLoading(true);
    const getAllCompanies = props.firebase.functions.httpsCallable('getAllCompanies');
    getAllCompanies().then(function (result: any) {
      setResults({
        companies: result.data
      });
      setLoading(false);
    }).catch(function (error: any) {
    });
  }

  function addCompany(company) {

    setLoading(true);
    return new Promise((resolve, reject) => {

      const addCompany = props.firebase.functions.httpsCallable('addCompany');
      addCompany({ company: company }).then(data => {
        setLoading(false);
        dispatch();
        resolve(data);
      }).catch(error => {
        setLoading(false);
        reject(error);
      });
    });
  }

  function updateCompany(company) {

    return new Promise((resolve, reject) => {

      const updateCompany = props.firebase.functions.httpsCallable('updateCompany');
      updateCompany({ company: company }).then(data => {
        dispatch();
        resolve(data);
      }).catch(error => {
        reject(error);
      })
    });
  }

  return (
    <div className="customers">

      <h1>Anbieter</h1>

      <div className="button-area">

        <Modal buttonText="Neuer Anbieter">
          <CompanyForm callback={addCompany} />
        </Modal>
      </div>


      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '30%' }}>Händler</TableCell>
                <TableCell style={{ width: '25%' }}>Kategorie</TableCell>
                <TableCell style={{ width: '30%' }}>Kontakt</TableCell>
                <TableCell style={{ width: '15%' }}>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.companies && results.companies.map((company: ICompany, i) => {
                const category = categories.filter((cat: any) => cat.id.toString() === company.categoryId.toString())[0]
                return (
                  <TableRow key={i} hover>
                    <TableCell>
                      <span className="key">ID:</span> <span className="value">{company.id}</span><br />
                      <span className="key">Stripe Account ID:</span> <span className="value">{company.stripeAccountId ? `${company.stripeAccountId}` : '-'}</span><br />
                      <strong>{company.name}</strong><br />
                      {company.street} {company.number}<br />
                      {company.plz} {company.city}
                    </TableCell>
                    <TableCell>{category.name}</TableCell>
                    <TableCell>
                      {company.email}<br />
                      {company.phone}<br />
                      {company.website}<br />
                    </TableCell>
                    <TableCell>
                      <Modal button={
                        <IconButton aria-label="edit">
                          <EditIcon />
                        </IconButton>
                      }>
                        <CompanyForm company={company} callback={updateCompany} />
                      </Modal>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
      {loading && <Loader full />}

    </div>
  )
}


const Companies = compose(
  observer,
  withRouter,
  withFirebase,
)(CompaniesBase);

export default Companies;
