const categories = [
    { id: 1, name: 'Essen & Trinken' },
    { id: 2, name: 'Freizeit & Events' },
    { id: 3, name: 'Wellness & Beauty' },
    { id: 4, name: 'Gesundheit & Fitness' },
    { id: 5, name: 'Fashion' },
    { id: 6, name: 'Lebensmittel & Haushalt' },
    { id: 7, name: 'Elektronik' },
]

export default categories;