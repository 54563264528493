import { observable } from 'mobx';
import { createContext } from "react";

class SnackStore {
  @observable
  success: any = { show: false, msg: '' };
  @observable
  error: any = { show: false, msg: '' };

  setSuccess(msg, show = true) {
    this.success = { show: show, msg: msg };
  }

  setError(msg, show = true) {
    this.error = { show: show, msg: msg };
  }
}

const SnackStoreContext = createContext(new SnackStore());

export default SnackStoreContext;
