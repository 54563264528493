export const LOGIN: string = '/'; // Home
export const LOGIN_ERROR: string = '/error/:id';
export const DASHBOARD: string = '/dashboard';
export const XALES: string = '/xales';
export const XALES_EVALUATION: string = '/xales-evaluation';
export const PRODUCTS: string = '/products';
export const LOACTIONS: string = '/locations';
export const SETTINGS: string = '/settings';

// Admin routes
export const CUSTOMERS: string = '/customers';
export const COMPANIES: string = '/companies';
export const CMS: string = '/cms';
