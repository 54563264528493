import React, { useState, useEffect, useRef } from 'react'
import { TextField, Grid, Select, MenuItem } from '@material-ui/core';

import { SUPPORT_EMAIL } from '../../../constants/generals';

import './CodeImportForm.scss';

function CodeImportForm({ onCodeImport, qrCodes }: { onCodeImport: Function, qrCodes: any }) {
  const modalRef: any = useRef(null);
  const [codes, setCodes] = useState([]);

  function onKeyUp(event) {
    const value = event.target.value;
    let valueArray = value.split(/\r|\r\n|\n/);
    valueArray = valueArray.filter(n => n);
    valueArray = valueArray.map(n => n.trim());
    if (JSON.stringify(codes) !== JSON.stringify(valueArray)) {
      setCodes(valueArray);
    }
  }

  function onClick() {
    onCodeImport(codes);
    if (modalRef.current.previousSibling) {
      modalRef.current.previousSibling.click(); // Quickfix
    }
  }

  useEffect(() => {
    if (qrCodes.length > 0) {
      setCodes(qrCodes);
    }
  }, []);

  return (
    <div className="code-import-form" ref={modalRef}>
      <h2>QR Codes</h2>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <p>
            Du kannst hier beliebig viele Codes hochladen. Diese werden nach erfolgreichem Kauf in der App angezeigt (QR Code und Code).
          </p>
          <p>
            Wenn du dir unsicher bist oder du Fragen hast, wende dich gerne an den Support: <a href={'mailto:' + SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a>
          </p>
        </Grid>

        <Grid item xs={12}>
          <div className="code-import-form__form">
            <TextField
              onKeyUp={onKeyUp}
              className=""
              fullWidth
              label="Codes"
              defaultValue={qrCodes.toString().split(',').join("\n")}
              placeholder={`Zum Beispiel:\n\nFX00001\nFX00002\nFX00003\nFX00004\nFX00005\nFX00006\nFX00007\nFX00008\n...`}
              name="xaleCodes"
              rows={30}
              multiline={true} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="code-import-form__counter"><strong>{codes.length}</strong> {codes.length === 1 ? 'Code' : 'Codes'}</div>
        </Grid>

        <Grid item xs={12}>

          <React.Fragment>
            <button disabled={codes.length === 0} onClick={onClick} type="submit" className="button primary">Speichern</button>
          </React.Fragment>
        </Grid>

      </Grid>

    </div>
  )
}

export default CodeImportForm;
