import React from 'react'
import './Status.scss';

function Status(props) {

    return (
        <span className={'status ' + props.type}>
          {props.msg ? props.msg :
            <React.Fragment>
              {props.type === 'planning' && 'Bevorstehend'}
              {props.type === 'ended' && (props.stopped ? 'Vorzeitig beendet' : 'Beendet')}
              {props.type === 'running' && 'Läuft aktuell'}
            </React.Fragment>
          }
        </span>
    )
}

export default Status;
