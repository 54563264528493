import React, { useContext, useState, useEffect } from 'react'
import MModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '../../atoms/forms/Button';
import { IModal } from '../../../types/IModal';
import { observer } from "mobx-react-lite";
import ModalStoreContext from '../../../store/ModalStore';

import './Modal.scss';

function Modal(props: IModal) {
  const [active, setActive] = useState(false);
  const modalStore = useContext(ModalStoreContext);

  useEffect(() => {

    if (!modalStore.active) {
      setActive(false);
    }
  }, [modalStore.active]);

  const handleOpen = () => {
    modalStore.active = true;
    setActive(true);
  };

  const onClose = () => {
    setActive(false);
  };

  return (
    <React.Fragment>
      {props.buttonText &&
        <Button type="primary" onClick={handleOpen}>
          {props.buttonText}
        </Button>
      }
      {props.button &&
        <span onClick={handleOpen}>
          {props.button}
        </span>
      }

      <MModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modal"
        open={active}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={active}>
          <div className="paper">
            <span className="modal__close" onClick={onClose}></span>
            {props.children}
          </div>
        </Fade>
      </MModal>
    </React.Fragment>
  );
}

export default observer(Modal);
