import React, { useState, useEffect, useContext } from 'react'

import { TextField, Grid, Select, MenuItem } from '@material-ui/core';

import { withRouter } from 'react-router-dom';
import { withFirebase } from '../../../components/Firebase';

// NPM
import { compose } from 'recompose';
import { observer } from "mobx-react-lite";

// Store
import SnackStoreContext from '../../../store/SnackStore';

// Components
import Loader from '../../../components/atoms/Loader';
import Button from '../../../components/atoms/forms/Button';
import ShadowBox from '../../../components/molecules/ShadowBox';


// Stylesheet
import './Cms.scss';


function CompaniesBase(props: any) {
  const [loading, setLoading] = useState(false);
  const [imprint, setImprint] = useState<any>('');
  const [privacy, setPrivacy] = useState<any>('');
  const snackStore = useContext(SnackStoreContext);


  useEffect(() => {
    dispatch();
  }, []);

  function dispatch() {

    props.firebase.getContent('imprint').then((snapshot: any) => {
      const { text } = snapshot.data();
      setImprint(text);
      setLoading(false);
    });

    props.firebase.getContent('privacy').then((snapshot: any) => {
      const { text } = snapshot.data();
      setPrivacy(text);
      setLoading(false);
    })

    setLoading(true);
  }

  function saveImprint() {
    props.firebase.setContent('imprint', imprint).then(data => {
      snackStore.setSuccess('Impressum wurde aktualisiert.');
    }).catch(e => {
      snackStore.setError('Konnte nicht gespeichert werden.')
    });
  }

  function savePrivacy() {
    props.firebase.setContent('privacy', privacy).then(data => {
      snackStore.setSuccess('Datenschutzerklärung wurde aktualisiert.');
    }).catch(e => {
      snackStore.setError('Konnte nicht gespeichert werden.')
    });
  }


  return (
    <div className="cms">

      <h1>Content Management</h1>

      <ShadowBox>
        <h2>Impressum</h2>
        <br /><br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              defaultValue={imprint || ''}
              fullWidth
              type="text"
              rows={30}
              multiline={true}
              onChange={(event) => setImprint(event.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={saveImprint} variant="primary">Speichern</Button>
          </Grid>
        </Grid>
      </ShadowBox>

      <br /><br />
      <br /><br />

      <ShadowBox>
        <h2>Datenschutzerklärung</h2>
        <br /><br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              defaultValue={privacy || ''}
              fullWidth
              type="text"
              rows={30}
              multiline={true}
              onChange={(event) => setPrivacy(event.target.value)} />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={savePrivacy} variant="primary">Speichern</Button>
          </Grid>
        </Grid>
      </ShadowBox>

      {loading && <Loader full />}

    </div>
  )
}


const Companies = compose(
  observer,
  withRouter,
  withFirebase,
)(CompaniesBase);

export default Companies;
