import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../components/Firebase';
import * as ROUTES from '../../constants/routes';
import { FIREBASE_ERRORS } from '../../constants/errors';

import Input from '../../components/atoms/forms/Input';
import Button from '../../components/atoms/forms/Button';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import { SUPPORT_EMAIL } from '../../constants/generals';

import Logo from '../../assets/images/logo-text_white_cloud.svg';
import './Login.scss';

function LoginForm(props: any) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [toggleForgotPasswort, setToggleForgotPasswort] = useState(false);
  const [toggleForgotPasswortMessage, setToggleForgotPasswortMessage] = useState('');

  useEffect(() => {
    props.firebase.doSignOut();
    setHeight();
    window.addEventListener('resize', setHeight);
  }, []);

  function setHeight() {
    const vh: Number = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  function loginToFirebase() {
    console.log('loginToFirebase', username)
    props.firebase.doSignInWithEmailAndPassword(username, password)
      .then((result: any) => {
        props.history.push(ROUTES.XALES);
      })
      .catch((e: any) => {
        setError(FIREBASE_ERRORS[e.code]);
      });
  }

  function resetPassword() {
    props.firebase.resetPassword(email).then(data => {
      setToggleForgotPasswortMessage('Link wurde versendet. Schau bitte in deinen Postfach.')
    })
      .catch(e => {
        setToggleForgotPasswortMessage(`Irgendwas lief schief und wir konnten Dich nicht finden. Melde dich bitte beim Support: <a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`);
      });
  }

  function BtnLogin() {
    return (
      <React.Fragment>
        <ExitToAppIcon /> Login
      </React.Fragment>
    )
  }

  function BtnReset() {
    return (
      <React.Fragment>
        <RotateLeftIcon /> Passwort zurücksetzen
      </React.Fragment>
    )
  }

  return (
    <div className="login">
      <div className="login__box">
        <img src={Logo} alt="fireXale Cloud" />

        <div className={"login__flex" + (toggleForgotPasswort ? ' is-dirty' : '')}>
          <div className="login__login">
            <Input onChange={(event: any) => setUsername(event.target.value)} type="email" name="username" placeholder="Email" />
            <Input onChange={(event: any) => setPassword(event.target.value)} type="password" name="password" placeholder="Passwort" />
            {error && <span className="error">{error}</span>}
            <span className="link" onClick={() => setToggleForgotPasswort(true)}>Passwort vergessen</span>
            <Button variant="secondary" onClick={loginToFirebase}><BtnLogin /></Button>
          </div>
          <div className="login__forgot-password">
            <span className="link back" onClick={() => setToggleForgotPasswort(false)}>
              <ArrowBackIosIcon /> Zurück
            </span>
            {!toggleForgotPasswortMessage &&
              <>
                <p>
                  Wenn Du Dein Passwort vergessen hast, kannst Du Dir hier ein Link zum zurücksetzen schicken.
                </p>
                <Input onChange={(event: any) => setEmail(event.target.value)} type="text" name="email" placeholder="Email" />
                <Button variant="secondary" onClick={resetPassword}><BtnReset /></Button>
              </>
            }
            <p dangerouslySetInnerHTML={{ __html: toggleForgotPasswortMessage }} />
          </div>
        </div>
      </div>
    </div>
  )
}


const Login = compose(
  withRouter,
  withFirebase,
)(LoginForm);

export default Login;
