import { observable, action } from 'mobx';
import { createContext } from "react";

class CustomerStore {
  @observable
  customer: any = {};

  @observable
  company: any = {};

  @observable
  locations: any = {};

  @observable
  products: any = {};

  @observable
  xales: any = {};

  @observable
  test: number = 0;

  @action
  setCompany(company) {
    this.company = company;
  }

  @action
  setCustomer(customer) {
    this.customer = customer;
  }

  @action
  setLocations(locations) {
    this.locations = locations;
  }

  @action
  setProducts(products) {
    this.products = products;
  }

  @action
  setXales(xales) {
    this.xales = xales;
  }

  @action
  getXales() {

    let current: any = [];
    let planning: any = [];
    let end: any = [];

    this.xales.map((xale, i) => {

      const startTimestamp = xale.selectedStartDate.seconds * 1000;
      const endTimestamp = xale.selectedEndDate.seconds * 1000;
      let stoppedTimestamp;
      if (xale.stoppedDate) {
        stoppedTimestamp = xale.stoppedDate.seconds * 1000;
      }

      if (Date.now() >= startTimestamp && Date.now() <= endTimestamp) {
        current.push({ ...xale, status: 'running' });
      }
      if (Date.now() > endTimestamp) {
        end.push({ ...xale, status: 'ended' });
      }
      if (Date.now() < startTimestamp) {
        planning.push({ ...xale, status: 'planning' });
      }
    });

    const arr = [
      ...current,
      ...planning,
      ...end
    ];

    return arr;
  }
}

const CustomerStoreContext = createContext(new CustomerStore());

export default CustomerStoreContext;
