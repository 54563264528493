import 'date-fns';
import React, { useRef, useEffect, useState, useContext } from 'react'

// Material UI
import {
  Grid,
  Select,
  TextField,
  Checkbox,
  FormControlLabel,
  Slider,
  Switch
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// NPM
import DateFnsUtils from '@date-io/date-fns';
import { useForm } from 'react-hook-form';

// Components
import ModalStoreContext from '../../../store/ModalStore';
import Loader from '../../atoms/Loader';
import Button from '../../atoms/forms/Button';
import Modal from '../../organisms/Modal';
import CodeImportForm from '../CodeImportForm';
import { withFirebase } from '../../Firebase';


// Stylesheet
import './XalesForm.scss';


function XaleGroup(props) {

  return (
    <div className="xale-group">
      <h3>{props.headline}</h3>
      <div className="xale-group__content">
        {props.children}
      </div>
    </div>
  )
}


function XaleForm(props: any) {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(props.customerStore.products[0].id);
  const [locations, setLocations] = useState([]);
  const [qrCodes, setQrCodes] = useState([]);
  const [qrSwitch, setQrSwitch] = useState({
    checkedA: false,
  });
  const [xalePrice, setXalePrice] = useState('0');
  const [error, setError] = useState({ message: '', code: '' });
  const [discount, setDiscount] = useState<number | number[]>(10);
  const form: any = useRef(null);
  const modalStore = useContext(ModalStoreContext);
  const { handleSubmit, register, errors } = useForm();
  const currentDate = new Date();

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(() => {
    let defaultStart = new Date();
    defaultStart.setMinutes(defaultStart.getMinutes() + 5);
    return defaultStart;
  }

  );
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(() => {
    let defaultEnd = new Date();
    defaultEnd.setHours(defaultEnd.getHours() + 1);
    return defaultEnd;
  });

  function onSubmit(values) {
    setLoading(true);

    let isLocationSelected: boolean = false;
    props.customerStore.locations.map((location, i) => {
      if (locations[location.id]) {
        isLocationSelected = true;
      }
    });

    if (!isLocationSelected) {
      setErrorMessage('Bitte mindestens einen Standort auswählen.');
      return;
    }

    if (selectedStartDate && selectedEndDate) {

      if (!props.isLive && selectedStartDate < currentDate) {
        setErrorMessage('Der Startzeitpunkt muss in der Zukunft liegen.');
        return;
      }

      if (selectedStartDate >= selectedEndDate) {
        setErrorMessage('Der Endzeitpunkt darf nicht vor dem Startzeitpunkt liegen.');
        return;
      }

      let locs: any = [];
      Object.keys(locations).filter(id => {
        if (locations[id]) {
          locs.push(id);
        }
      })

      let object: any = {
        locationIds: locs,
        productId: product,
        selectedStartDate: selectedStartDate,
        selectedEndDate: selectedEndDate,
        discount: discount,
        infoText: values.info_text,
      };

      if (!props.isLive) {
        let countProducts = parseInt(values.count_products);
        const hasQr = qrSwitch.checkedA && qrCodes.length > 0;
        if (hasQr) {
          countProducts = qrCodes.length;
        }
        object.countProducts = countProducts;
        object.hasQrCodes = hasQr;
      }

      props.callback(object, qrCodes, props.xale ? props.xale.id : null).then((data) => {
        clearErrorMsg();
        closeModal();
        setLoading(false);
      })
    }
    else {
      setErrorMessage('Bitte ein gültiges Datum auswählen.');
    }
  }

  const handleStartDateChange = (date: Date | null) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setSelectedEndDate(date);
  };

  function setErrorMessage(text) {
    setError({
      ...error,
      message: text,
    });
    setLoading(false);
  }

  function clearErrorMsg() {
    setError({
      ...error,
      message: '',
    });
  }

  function closeModal() {
    modalStore.active = false;
  }

  function handleChangeCheckbox(locationId: string) {
    setLocations(prevState => {
      return { ...prevState, [locationId]: !locations[locationId] }
    })
  }

  function selectAllLocations() {
    let arr: any = [];
    Object.keys(locations).map(l => {
      arr[l] = true;
    });
    setLocations(arr);
  }

  function onSliderChange(event: any, newValue: number | number[]) {
    setDiscount(newValue);
    calculateXalePrice(product, newValue);
  }

  function calculateXalePrice(productId: string, discount: any) {
    let productPrice = props.customerStore.products.filter(p => p.id === productId)[0].price;
    productPrice = parseFloat(productPrice.replace(',', '.'));
    const xalePriceTmp = productPrice - (productPrice * discount / 100);
    setXalePrice(xalePriceTmp.toFixed(2).replace('.', ','));
  }

  function onCodeImport(codes: any) {
    setQrCodes(codes);
  }

  function onChangeQrSwitch(event) {
    // setQrCodes([]);
    setQrSwitch({ ...qrSwitch, [event.target.name]: event.target.checked });
  }

  function initExisitingXale() {
    setProduct(props.xale.productId);
    setDiscount(props.xale.discount);
    calculateXalePrice(props.xale.productId, props.xale.discount);
    setSelectedStartDate(new Date(props.xale.selectedStartDate.seconds * 1000));
    setSelectedEndDate(new Date(props.xale.selectedEndDate.seconds * 1000));
  }

  useEffect(() => {

    if (props.xale) {

      if (props.xale.hasQrCodes) {
        props.firebase.getQrCodes(props.xale.id).then((snapshot) => {
          setQrCodes(snapshot.data().codes);
          setQrSwitch({ checkedA: true });
          initExisitingXale();
        }).catch((e) => {
          console.log('e', e.message);
        })


      }
      else {
        initExisitingXale();
      }
    }
    else {
      calculateXalePrice(product, discount);
    }

    let arr: any = [];
    props.customerStore.locations.map(location => {
      if (props.xale) {
        if (props.xale.locationIds.filter(id => id === location.id).length > 0) {
          arr[location.id] = true;
        }
        else {
          arr[location.id] = false;
        }
      }
      else {
        arr[location.id] = false;
      }
    });
    setLocations(arr);
  }, []);

  return (
    <div className="xales-form">

      {props.xale ? <h2>{props.customerStore.products.filter(p => p.id === props.xale.productId)[0].name} bearbeiten</h2>
        : <h2>Neuer Xale</h2>}

      {props.isLive && <div className="xales-form__attention">Achtung! Der Xale ist live. </div>}

      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>

            <XaleGroup headline="Auswahl der Standorte">

              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <label className="custom">Standorte</label><br />
                  <div>
                    {props.customerStore.locations.map((location, i) => {
                      return <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            disabled={props.isLive}
                            checked={locations[location.id] ? locations[location.id] : false}
                            onChange={() => handleChangeCheckbox(location.id)}
                            name={location.id}
                          />
                        }
                        label={location.name}
                      />
                    })}
                  </div>
                  {!props.isLive && <span className="link select-all" onClick={selectAllLocations}>Alle auswählen</span>}
                </Grid>
              </Grid>
            </XaleGroup>

            <XaleGroup headline="Produkt und Rabatt">

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>

                  <label className="custom">Produkt</label>
                  <Select
                    disabled={props.isLive}
                    fullWidth
                    native
                    labelId="products"
                    id="products-select"
                    defaultValue={props.xale ? props.xale.productId : '0'}
                    onChange={(value: any) => {
                      setProduct(value.target.value);
                      calculateXalePrice(value.target.value, discount);
                    }}
                  >
                    {props.customerStore.products.map((product, i) => {
                      return <option key={i} value={product.id}>{product.name} ({product.price} €)</option>
                    })}
                  </Select>
                </Grid>

                <Grid item xs={12} md={6}>
                  <label className="custom">Rabatt in %</label>
                  <Slider
                    disabled={props.isLive}
                    defaultValue={10}
                    value={discount}
                    aria-labelledby="discrete-slider-custom"
                    name="discount"
                    step={1}
                    valueLabelDisplay="auto"
                    min={0}
                    max={100}
                    onChange={onSliderChange}
                    track="inverted"
                  />
                </Grid>
                <Grid item xs={12} className="align-center">
                  Dieser Xale wird für <strong>{xalePrice}</strong> € (inkl. MwSt.) verkauft ({discount} % Rabatt).
                </Grid>
              </Grid>
            </XaleGroup>

            <XaleGroup headline="Anzahl der Xales">

              {props.isLive ?
                <Grid container spacing={3}>
                  <Grid item xs={12} className="align-center">Du kannst die Anzahl der Xales nicht verändern, da dieser Xale live ist.</Grid>
                </Grid>
                :
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <div className={(qrSwitch.checkedA || (props.xale && props.isLive && props.xale.hasQrCodes)) ? 'hidden' : ''}>
                      <TextField
                        disabled={qrSwitch.checkedA || (props.xale && props.isLive && props.xale.hasQrCodes)}
                        fullWidth
                        className="margin-bottom"
                        label="Anzahl Produkte*"
                        name="count_products"
                        type="number"
                        inputProps={{ min: "1" }}
                        defaultValue={props.xale ? props.xale.countProducts : '1'}
                        error={errors.count_products ? true : false}
                        helperText={errors.count_products && 'Bitte die Anzahl der zu verkaufenden Produkte angeben'}
                        inputRef={register({
                          required: 'Required',
                          pattern: /[0-9]/
                        })} />
                    </div>
                  </Grid>

                  <React.Fragment>

                    <Grid item xs={12} md={6}>

                      <Grid container spacing={3}>

                        <Grid item xs={12}>

                          <FormControlLabel
                            control={<Switch checked={qrSwitch.checkedA} onChange={onChangeQrSwitch} name="checkedA" />}
                            label="Ich habe QR Codes"
                          />
                          {qrSwitch.checkedA &&
                            <React.Fragment>
                              <Modal buttonText="Codes hochladen">
                                <React.Fragment>
                                  <CodeImportForm onCodeImport={onCodeImport} qrCodes={qrCodes} />
                                </React.Fragment>
                              </Modal>
                            </React.Fragment>
                          }
                        </Grid>
                        {qrSwitch.checkedA && <Grid item xs={12}>
                          <div>Du lädst <strong>{qrCodes.length}</strong> Codes hoch und damit auch <strong>{qrCodes.length}</strong> Xales</div>
                        </Grid>}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                </Grid>
              }

            </XaleGroup>

            <XaleGroup headline="Zeitraum">
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    disabled={props.isLive}
                    className="full-width"
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="startdate-picker-inline"
                    label="Start Datum"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <KeyboardTimePicker
                    disabled={props.isLive}
                    className="full-width"
                    ampm={false}
                    clearable
                    margin="normal"
                    id="starttime-picker"
                    label="Start Zeitpunkt"
                    value={selectedStartDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <KeyboardDatePicker
                    className="full-width"
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="enddate-picker-inline"
                    label="End Datum"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <KeyboardTimePicker
                    className="full-width"
                    ampm={false}
                    clearable
                    margin="normal"
                    id="endtime-picker"
                    label="End Zeitpunkt"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>

              </Grid>
            </XaleGroup>

            <XaleGroup headline="Diverses">
              <Grid container spacing={3}>
                <Grid item xs={12}>

                  <TextField
                    fullWidth
                    className="margin-bottom"
                    label='Info Text (zB. "Termin geht von 13 bis 14 Uhr" oder "In der Europapassage", ...)'
                    name="info_text"
                    type="text"
                    inputProps={{
                      maxLength: 64,
                    }}
                    defaultValue={props.xale ? props.xale.infoText : ''}
                    inputRef={register()} />
                </Grid>
              </Grid>
            </XaleGroup>

            <Grid item xs={12}>
              <span className="error">{error.message}</span>
            </Grid>
            <Grid item xs={12}>
              {loading ?
                <Loader /> :
                <React.Fragment>
                  <button type="submit" className="button primary">Speichern</button>
                  <Button onClick={closeModal} variant="tertiary">Abbrechen</Button>
                </React.Fragment>
              }
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </form>
    </div >
  )
}

export default withFirebase(XaleForm);
